import { Component, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Loader } from "@googlemaps/js-api-loader"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Teste';

 //  document: Document;
  constructor(
    @Inject(DOCUMENT) private document: Document,
  private elementRef:ElementRef) {};

ngAfterViewInit() {
var s = this.document.createElement("script");
s.type = "text/javascript";
s.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDKh1Hv7HtJ5DgxcUeUcw-nEldNpNsFpv0Y";
// this.elementRef.nativeElement.appendChild(s);
console.log('ngAfterViewInit() ->  after view init');

}
onActivate(event: any) {
  console.log('onActivate', event);
}
onDeactivate(event: any) {
  console.log('onDeactivate', event);
}
onAttach(event: any) {
  console.log('onAttach', event);
}
onDetach(event: any) {
  console.log('onDetach', event);
}
}
