import { LDiarios } from './../user/ldiarios.types';

export class ULDiarios {
  u_ldiariosstamp?: string;
  u_rdiariosstamp: string;
  tipo?: number;
  objetivo?: number;
  ausente?: boolean;
  comentarios?: string;
  no?: number;
  agno ?: number;
  estab?: number;
  nome?: string;
  email ?: string;
  localidade?: string;
  codigoPostal ?: string;
  nif ?: string;
  morada?: string;
  contacto?: string;
  latitude?: number;
  longitude?: number;
  ousrinis?: string;
  ousrdata?: Date;
  ousrhora?: string;
  usrinis?: string;
  usrdata?: Date;
  usrhora?: string;
  marcada?: boolean;
  inicio?: Date;
  fim?: Date;
  duracao?: number;
  obs?: string;
  encomenda?: boolean;
  objectivo?: number;
  isvenda?:boolean;
  isposvenda?:boolean;
isreclamacao?:boolean;
iscobranca?:boolean;
phctipo?:string;

  constructor(
    linha: LDiarios = { u_rdiariosstamp: '' }
  ) {
    console.log('linha' , linha);
    //criar os campos com valores por defeito
    linha.u_ldiariosstamp = linha.u_ldiariosstamp || '';
    linha.u_rdiariosstamp = linha.u_rdiariosstamp || '';
    linha.tipo = linha.tipo || 0;

    linha.ausente = linha.ausente || false;
    linha.comentarios = linha.comentarios || '';
    linha.no = linha.no || 0;
    linha.agno = linha.agno || 0;
    linha.estab = linha.estab || 0;
    linha.nome = linha.nome || '';
    linha.localidade = linha.localidade || '';
    linha.contacto = linha.contacto || '';
    linha.latitude = linha.latitude || 0;
    linha.longitude = linha.longitude || 0;
    linha.ousrinis = linha.ousrinis || '';
    linha.ousrdata = linha.ousrdata || new Date();
    linha.ousrhora = linha.ousrhora || '';
    linha.usrinis = linha.usrinis || '';
    linha.usrdata = linha.usrdata || new Date();
    linha.usrhora = linha.usrhora || '';
    linha.obs = linha.obs || '';
    linha.encomenda = linha.encomenda || false;
    linha.objectivo =  linha.objectivo || 0;
    // o inicio é a hora de inicio  hh:mm
    let ini = new Date();
    // se o inicio for string na forma  "11:30" então ini.setHours(11) e ini.setMinutes(30)



    //ini.setHours()

    // linha.inicio = linha.inicio || ini.getHours() + ':' + ini.getMinutes();
    linha.inicio = linha.inicio || ini;
    linha.fim = linha.fim || ini;
    linha.duracao = linha.duracao || 0;


    this.update(linha);

  }
  update(linha: LDiarios | any) {
    console.log('linha' , linha);

    this.u_ldiariosstamp = linha.u_ldiariosstamp;
    this.u_rdiariosstamp = linha.u_rdiariosstamp;
    this.tipo = linha.tipo;
    this.objetivo = linha.objetivo;
    this.ausente = linha.ausente;
    this.comentarios = linha.comentarios;
    this.no = linha.no;
    this.estab = linha.estab;
    this.nome = linha.nome;
    this.localidade = linha.localidade;
    this.contacto = linha.contacto;
    this.latitude = linha.latitude;
    this.longitude = linha.longitude;
    this.ousrinis = linha.ousrinis;
    this.ousrdata = linha.ousrdata;
    this.ousrhora = linha.ousrhora;
    this.usrinis = linha.usrinis;
    this.usrdata = linha.usrdata;
    this.usrhora = linha.usrhora;
    this.marcada = linha.marcada;
    this.inicio = linha.inicio;
    this.fim = linha.fim;
    this.duracao = linha.duracao;
    this.objectivo = linha.objectivo;
    this.obs = linha.obs;
    this.encomenda = linha.encomenda;
  }
}
