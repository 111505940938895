import { Component, OnInit } from '@angular/core';
import { McriosService } from '../core/app/mcrios.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common'
@Component({
  selector: 'app-detalhes-vendedor',
  templateUrl: './detalhes-vendedor.component.html',
  styleUrls: ['./detalhes-vendedor.component.css']
})
export class DetalhesVendedorComponent  implements OnInit {
  public cm: Observable<any>;
  private _no: any;
  constructor(private _mcriosService: McriosService, private route: ActivatedRoute,private location: Location) {
    // id parametro do url
     this._no = this.route.snapshot.paramMap.get('no');
     console.log(['this._id', this._no]);

  }


  ngOnInit(): void {
    console.log('ngOnInit');

    this._mcriosService.getVendedor(this._no).subscribe((data: any) => {
      console.log(['data', data]);
      this.cm = data;
    }, (error) => {
      console.log(['error', error]);
    });
}

back(): void {
  this.location.back()
}
}
