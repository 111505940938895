<app-navbar></app-navbar>

<!--Top Bar Title-->
<nav class="max-w-screen bg-white border-gray-200 px-4 py-3 mx-auto top-bar">
  <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
      <a href="/mcrios" class="inline-flex items-center">
        <svg class="animate__animated animate__fadeInRight w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
        <h1 class="animate__animated animate__fadeIn titulo-top items-center pl-3">Novo Vendedor</h1>
      </a>
  </div>
</nav>


<!-- Novo Vendedor -->
<form class="max-w-screen-xl px-4 py-3 mx-auto second-content" action="#">
  <div class="mb-6">
    <label for="user" class="block mb-2 text-sm font-medium text-gray-900">Nome de Utilizador</label>
    <input [formControl]="user" type="text" id="user" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5" required>
    <app-erro *ngIf="user.invalid && user.touched"></app-erro>
  </div>

  <div class="mb-6">
    <label class="block mb-2 text-sm font-medium text-gray-900">Palavra-Passe</label>
    <input [formControl]="password" placeholder="" type="password" x-model="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5">
    <app-erro *ngIf="password.invalid && password.touched"></app-erro>
  </div>

  <div class="mb-6">
    <label class="block mb-2 text-sm font-medium text-gray-900">Confirmar Palavra-Passe</label>
    <input [formControl]="confirm" placeholder="" type="password" x-model="password_confirm" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5">
    <app-erro *ngIf="confirm.invalid && confirm.touched"></app-erro>
  </div>

  <div class="mb-6">
    <label for="nome" class="block mb-2 text-sm font-medium text-gray-900">Nome</label>
    <input [formControl]="nome" type="text" id="nome" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5" required>
    <app-erro *ngIf="nome.invalid && nome.touched"></app-erro>
  </div>

  <div class="mb-6">
    <label for="numero" class="block mb-2 text-sm font-medium text-gray-900">Número</label>
    <input [formControl]="numero" type="number" id="numero" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5" required>
    <app-erro *ngIf="numero.invalid && numero.touched"></app-erro>
  </div>

  <div class="mb-6">
    <label for="matricula" class="block mb-2 text-sm font-medium text-gray-900">Matrícula da Viatura</label>
    <input [formControl]="matricula" type="text" id="matricula" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5" maxlength="6" pattern="^([A-Za-z]{2}-?[0-9]{2}-?[A-Za-z]{2})?([0-9]{2}-?[A-Za-z]{2}-?[0-9]{2})?([0-9]{2}-?[A-Za-z]{2}-?[0-9]{2})?$" required>
    <app-erro *ngIf="matricula.invalid && matricula.touched"></app-erro>
  </div>

  <!--
  <div class="mb-6">
    <label for="kms-inicio" class="block mb-2 text-sm font-medium text-gray-900">Kms (Entrada)</label>
    <input [formControl]="kms" type="number" id="kms-inicio" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5" required>
    <app-erro *ngIf="kms.invalid && kms.touched"></app-erro>
  </div>-->

  <button type="submit" class="text-white bg-[#955e1b] hover:bg-[#955e1b] focus:outline-none hover:scale-105 duration-75 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-28">
    <svg class="w-6 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
  </button>
</form>
