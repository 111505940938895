
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SidebarModule } from 'primeng/sidebar';
import { AppRoutingModule } from './app-routing.module';
import { MaterialUiModule } from './material-ui/material-ui.module'
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './navbar/navbar.component';
import { AppMcriosComponent } from './app-mcrios/app-mcrios.component';
import { ListRelDiariosComponent } from './list-rel-diarios/list-rel-diarios.component';
import { NovoRelDiarioComponent } from './novo-rel-diario/novo-rel-diario.component';
import { NovoVendedorComponent } from './novo-vendedor/novo-vendedor.component';
import { DetalhesRelDiarioComponent } from './detalhes-rel-diario/detalhes-rel-diario.component';
import { ListVendedoresComponent } from './list-vendedores/list-vendedores.component';
import { DetalhesVendedorComponent } from './detalhes-vendedor/detalhes-vendedor.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { ErroComponent } from './erro/erro.component';
import { NgIf } from '@angular/common';
import { ListEntradasSaidasComponent } from './list-entradas-saidas/list-entradas-saidas.component';
import { DetalhesEntradasSaidasComponent } from './detalhes-entradas-saidas/detalhes-entradas-saidas.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { MatTimepickerModule } from 'mat-timepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { GoogleMapsModule } from '@angular/google-maps';
import { ChangelogComponent } from './changelog/changelog.component';
import { Select2Module } from 'ng-select2-component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    AppMcriosComponent,
    ListRelDiariosComponent,
    NovoRelDiarioComponent,
    NovoVendedorComponent,
    DetalhesRelDiarioComponent,
    ListVendedoresComponent,
    DetalhesVendedorComponent,
    ErroComponent,
    ListEntradasSaidasComponent,
    DetalhesEntradasSaidasComponent,
    ChangelogComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,NgSelectModule,
    FormsModule, ReactiveFormsModule,
    MaterialUiModule,
    SidebarModule,
    ButtonModule,
    DialogModule,
    MatTooltipModule,
    RadioButtonModule,
    FileUploadModule,
    CoreModule,
    NgIf,
    HttpClientModule,
//    MatTimepickerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    GoogleMapsModule,
    Select2Module

  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },{
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
  },
  {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public router: Router) {

  }
}


