import { environment } from './../../environments/environment';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { VERSION } from '../../environments/version';


import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {


 @ViewChild('signInNgForm') signInNgForm: NgForm;
    signInForm: FormGroup;
  version: any;
  public hide: boolean;
  environment = environment;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _formBuilder: FormBuilder,) {
    this.hide = true;
    this.version = VERSION;
    this.environment = environment;
  }

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.signInForm = this._formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]

    });

  }

  onSubmit() {

    this._router.navigateByUrl('/mcrios');
  }


  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }


     /**
     * Sign in
     */
     signIn(): void {
      // Return if the form is invalid
      if (this.signInForm.invalid) {
          return;
      }

      // Disable the form
      this.signInForm.disable();


      // Sign in
      this._authService.signIn(this.signInForm.value)
          .subscribe(
              (user) => {





                  if (this.signInForm.value['rememberMe']) {

                      console.log(user);
                      console.log('Analk');
                      console.log(this.signInForm);
                      localStorage.setItem('Nome', user.nome);
                      localStorage.setItem('accessToken', user.apiToken);

                  }



                  // Set the redirect url.
                  // The '/signed-in-redirect' is a dummy url to catch the request and redirect the user
                  // to the correct page after a successful sign in. This way, that url can be set via
                  // routing file and we don't have to touch here.
                  const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/mcrios';

                  // Navigate to the redirect url
                  this._router.navigateByUrl(redirectURL);

              },
              (error) => {


                  //     console.log(error);
                  // Re-enable the form
                  this.signInForm.enable();

                  // Reset the form
                  this.signInNgForm.resetForm();


                  // Set the alert
                  // @TODO: Alerta falha na password
                  Swal.fire('Alerta falha na password')



              }
          );
  }

}
