import { webconfig } from './../core/app/webconf';
import { McriosService } from './../core/app/mcrios.service';
import { NgModule, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { Router, NavigationEnd, UrlSerializer, DefaultUrlSerializer, UrlTree, UrlSegment, UrlSegmentGroup, PRIMARY_OUTLET, ActivatedRoute } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})


export class NavbarComponent implements  OnInit {

  rmenu(ev: any): void {
    console.log(   ['rmenu',ev ]);
  }

  showFiller = false;

  procurar: any;
  adicionar: any;
  menu: any;
  observacoes: any;
  addReport:boolean = false;
  btprocurar:boolean = true;
  visible: any;
  btSave: boolean;
  btCancel: boolean;

  showDialog() {
    this.visible = true;
  }

  constructor(public router: Router,
    private _authService: AuthService
    , private _mcriosService: McriosService,
    private route: ActivatedRoute
    ) {

  }


  logout() {
    console.log('logout');
    // redirecionar aparti do router quando o observable for concluido
    this._authService.signOut().subscribe(() => {

    }, (error) => {
      console.log( ['error', error ]);
    //  this.router.navigate(['/sign-in']);
      this.router.navigateByUrl('/sign-in?redirectURL='+ this.router.url);
    //  this.router.navigate(['items'], { relativeTo: this.route });
    }, () => {
      console.log('complete');
      console.log('User signed out!');
      this.router.navigate(['/']);
    }

    );

    //   localStorage.removeItem('token');
    //   this.router.navigate(['/']);
  }


  goto() {
    console.log('goto()');
    this.observacoes = false;
    this.adicionar = true;
    this.procurar = false;
    this.menu = false
    console.log('goto() - newDiario()');
    this._mcriosService.newDiario().subscribe((data: any) => {
      console.log(['newDiario() - subscribe()', data]);
      // this.diarios = data;
      // this.cm = data;
      console.log('newDiario() - subscribe() - data[0]', data);
     //  se  nao fechado  vamos editar o diario
      if (data.fechado == 0) {
        console.log('newDiario() - subscribe() - data[0].fechado == false');
        this.router.navigate(['/novo-relatorio-diario' , data.u_rdiariosstamp  ]);
      } else {
        console.log('newDiario() - subscribe() - data[0].fechado == true');
        this.router.navigate(['/detalhes-relatorio-diario', data.u_rdiariosstamp ]);
      }

    });



}

ngOnInit() {
  this.router.events.subscribe((event) => {
    console.log(event);
    if (event instanceof NavigationEnd) {
      // O evento NavigationEnd é acionado quando a navegação é concluída
      // Você pode acessar a nova URL através de event.url
      let dus = new DefaultUrlSerializer();
      const tree:UrlTree = dus.parse( event.url);
      const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
      console.log ( g);
      console.log('A URL foi alterada para: ', dus.parse( event.url) , g.children['relatorios-diarios'] );

      // if (g.children['relatorios-diarios'].segments  )
    // const ss = UrlSerializer(event.url)

      // Faça o que for necessário com a nova URL aqui
    }
  });

// vamos subscrever ao webconfig
this._mcriosService.getWebConfig().subscribe((data: webconfig) => {
  console.log(['getWebConfig()', data]);
  // this.diarios = data;
  // this.cm = data;
  console.log('getWebConfig() - data', data);
  this.showFiller = data.showFiller;
  this.procurar = data.procurar;
  this.adicionar = data.adicionar;
  this.menu = data.menu;
  this.observacoes = data.observacoes;
  this.addReport = data.addReport;
  this.btprocurar = data.btprocurar;
  this.btSave = data.btSave;
  this.btCancel = data.btCancel;
});


}

showProcura() {
  this.procurar = true; this.adicionar = false; this.observacoes = false; this.menu = false
}

cancelar() {
  this.router.navigate(['.'], { relativeTo: this.route ,skipLocationChange: true, queryParams: { refresh: new Date().getTime() } });
}
addCl() {
console.log('AdicionarCliente')
this._mcriosService.setEventos({tipo:'addCl', data: {}});
}

save() {
console.log('save')
this._mcriosService.setEventos({tipo:'saveReport', data: {}});
}
}





