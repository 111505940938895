import { Component, OnInit } from '@angular/core';
import { McriosService } from '../core/app/mcrios.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-list-rel-diarios',
  templateUrl: './list-rel-diarios.component.html',
  styleUrls: ['./list-rel-diarios.component.css'],
 // standalone: true,
 // imports: [ MatSlideToggleModule]
})
export class ListRelDiariosComponent implements OnInit {
  public diarios: Observable<any[]>;
  vendedores = new FormControl('');
  vendedoresList: string[] = [];
  color:  'accent';
  checked = false;
  disabled = false;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  onLoad: boolean = false;
  constructor(private _mcriosService: McriosService, private route: ActivatedRoute,public router: Router,) {
    // id parametro do url


  }
  ngOnInit(): void {
    console.log('ngOnInit');
this.onLoad = true;
    this._mcriosService.getRelatoriosDiarios().subscribe((data: any) => {
      console.log(['data', data]);
      this.onLoad = false;
      this.diarios = data;
      // this.cm = data;
    }, (error) => {
      console.log(['error', error]);
    });
    this.onLoad = true;
    this._mcriosService.getVendedores().subscribe(vendedores => {
      this.onLoad = false;
      vendedores.forEach(vend => {
        console.log(vend)
        this.vendedoresList.push(vend)

      });

    }
    );

}
goto(data) {
      console.log(['goto', data]);
      if (data.fechado == 0) {
        console.log('newDiario() - subscribe() - data[0].fechado == false');
        this.router.navigate(['/novo-relatorio-diario', data.u_rdiariosstamp ] );
      } else {
        console.log('newDiario() - subscribe() - data[0].fechado == true');
        this.router.navigate(['/detalhes-relatorio-diario', data.u_rdiariosstamp ]);
      }
}

submitPesquisa() {
  const  search = {
    vendedores: this.vendedores.value
    ,range: this.range.value

  }


  this.onLoad = true;
  this._mcriosService.getRelatoriosDiarios(search).subscribe((data: any) => {
    this.onLoad = false;
    console.log(['data', data]);
    this.diarios = data;
  }, (error) => {
    console.log(['error', error]);
  }
  );
     // console.log( ['getRelatoriosDiarios' , this.visitas])
    }




}
