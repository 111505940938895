/* eslint-disable @typescript-eslint/member-ordering */
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from './auth.utils';
import { UserService } from '../user/user.service';
import { EventSourcePolyfill } from 'ng-event-source';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    private apirul: string = '';

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    )
    {

        this.apirul = environment.apirul;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post( this.apirul  + 'api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post( this.apirul  + 'api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(this.apirul + '/loginjson', credentials).pipe(
            switchMap((response: any) => {
                console.log(response);

                // Store the access token in the local storage
                this.accessToken = response.apiToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response;

                // Return a new observable with the response
                return of(response);
            }
              )
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(accessToken:string): Observable<any>
    {


        let headers = new HttpHeaders()
        .set('content-type','application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('X-AUTH-TOKEN',  accessToken );

  //    console.log(accessToken)
  //    console.log('HEADERSsss')
        // Renew token
        return this._httpClient.get( this.apirul  + '/api/user/getmeToken', {
           headers: {
            'X-AUTH-TOKEN':  accessToken
           }
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {
              //  console.log('________ LOGIN TOKEN OK ')
              //  console.log(response)
                // Store the access token in the local storage
                this.accessToken = response.apiToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
       // return of(true);
       return this._httpClient.post( this.apirul  + '/logout', {} );
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post( this.apirul  + '/api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post( this.apirul  + '/api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
  //      if ( AuthUtils.isTokenExpired(this.accessToken) )
  //      {
  //          return of(false);
  //      }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken(this.accessToken );
    }




    private getEventSource(url: string, complete = false): EventSourcePolyfill {
        let surl =
            environment.mercureurl + '?topic=' + environment.apirul + url;
        if (complete) {
            surl = environment.mercureurl + '?topic=' + url;
        }
        const es = new EventSourcePolyfill(surl, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization:
                    'Bearer eyJhbGciOiJIUzI1NiJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6WyIqIl0sInB1Ymxpc2giOlsiKiJdfX0.DMfliuB71M8g8Kw3k6OzTx3ZeTODQBco5wvjTc3DodQ',
            },
        });
        console.log('@url -> ', url, '@surl-> ', surl);

        return es;
    }

    getServerSentEvent(url: string, complete = false): Observable<any> {
        return new Observable((observer) => {
            const eventSource = this.getEventSource(url, complete);

            //    eventSource.onmessage = event => {
            //        this.zone.run(() => observer.next(event));
            //    };
            eventSource.onmessage = (data) => {
               // this.zone.run(() => {
                    // Do stuff here
                        console.log('VER:  :: : ' , data );
                    observer.next(data);
               // });
            };
            eventSource.onopen = (a: any): void => {
                // Do stuff here
                console.log('@welcome link SSE Mercure !!! ', a);
            };
            eventSource.onerror = (e: any): void => {
                console.error(e);
                    console.log('ERRO', e);
                // Do stuff here
            };
        //    return of(observer);
        });
    }


    /**
     *
     * @param user Valida role de um User !
     * @param role
     * @returns
     */
    hasRole(user:any, role:any ): boolean {
        let has = false;

        if (typeof user === 'object') {
            if (user.roles.length > 0 ) {
              //  for (let index = 0; index < this.user.roles.length; index++) {
                  for (const [index, userrole] of user.roles) {
                 //   const userrole = this.user.roles[index];
                    if (userrole === role) {
                        has = true;
                   //     return true;
                    }
                }

            }
        }
        return has;
    }


}
