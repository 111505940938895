import { diarios } from './../core/user/diarios.types';
import {Component, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgFor, Location, DOCUMENT } from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { McriosService } from '../core/app/mcrios.service';
import { MapInfoWindow, MapMarker,GoogleMap } from '@angular/google-maps';
@Component({
  selector: 'app-list-entradas-saidas',
  templateUrl: './list-entradas-saidas.component.html',
  styleUrls: ['./list-entradas-saidas.component.css'],

})
export class ListEntradasSaidasComponent implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  vendedores = new FormControl('');
  vendedoresList: string[] = [];
diarios= []
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  onLoad: boolean = false;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 40.763111724333235, lng: -8.577399143979395 },
    zoom: 8,
  //  streetViewControl: false,
  //  mapTypeControl: false,
  //  fullscreenControl: false,
  }
  markerOptions: google.maps.MarkerOptions = {draggable: false,clickable: true,};
  map;
  visitas = [] ;
  rvisita= [  ] ;
  constructor(

    private _McriosService: McriosService,@Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,

  ) {
  }


  submitPesquisa() {

  const  search = {
    vendedores: this.vendedores.value
    ,range: this.range.value

  }
  console.log(search);
  this.onLoad = true;
  this._McriosService.getRelatoriosDiarios(search).subscribe(
diarios => {
  this.onLoad = false;
  this.diarios = diarios
  diarios.forEach(diario => {
    console.log(diario)
    diario.linhas.forEach(visita => {

    console.log(visita)
    if (visita.latitude && visita.longitude && +visita.Lotitude !== 0.000000) {
console.log( ['visita' , visita]) ;
      let  mark =  {
        position: { lat: +visita.latitude, lng: +visita.longitude },
        title: visita.nome,
        u_rdiariosstamp: visita.u_rdiariosstamp,
        u_ldiariosstamp: visita.u_ldiariosstamp,
        inicio: visita.inicio,
        fim: visita.fim,
        comentarios: visita.comentarios,
        localidade: visita.localidade,
       // icon: '<i class="fa fa-pizza-slice fa-lg"></i>'
       icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
    }
      this.visitas.push(mark)
    }
    });

  }
  );
  console.log( ['getRelatoriosDiarios' , this.visitas])
}

  );


  }

  ngOnInit(): void {

    this._McriosService.getVendedores().subscribe(vendedores => {

      vendedores.forEach(vend => {
        console.log(vend)
        this.vendedoresList.push(vend)

      });

    }
    );

    // Initialize and add the map
let map;
async function initMap(): Promise<void> {
  // The location of Uluru
  const position = { lat: -25.344, lng: 131.031 };

  // Request needed libraries.
  //@ts-ignore
  const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

  // The map, centered at Uluru
  /*
  map = new Map(
    document.getElementById('map') as HTMLElement,
    {
      zoom: 4,
      center: position,
      mapId: 'DEMO_MAP_ID',
    }
  );

  // The marker, positioned at Uluru
  const marker = new AdvancedMarkerElement({
    map: map,
    position: position,
    title: 'Uluru'
  });

  */
}

initMap();

  }

  event($event,stamp) {
    console.log($event,stamp)
    this.infoWindow.open(  $event );
  }
  openInfoWindow(marker: MapMarker,visita) {
    console.log(marker,visita)
    this.rvisita = visita
    this.infoWindow.open(marker);
  }





}


