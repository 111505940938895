import { Component, OnInit } from '@angular/core';
import  { McriosService } from '../core/app/mcrios.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-list-vendedores',
  templateUrl: './list-vendedores.component.html',
  styleUrls: ['./list-vendedores.component.css']
})
export class ListVendedoresComponent implements OnInit {
  public vendedores: Observable<any[]>;

  constructor(private _mcriosService: McriosService) {

  }
  ngOnInit(): void {
    console.log('ngOnInit');

    this._mcriosService.getVendedores().subscribe((data: any) => {
      console.log(['data', data]);
      this.vendedores = data;
    }, (error) => {
      console.log(['error', error]);
    });
}
}
