import { diarios } from "../user/diarios.types";
import { ULDiarios } from "./ULDiarios";

export class URDiarios {
  u_rdiariosstamp: string;
  cmdesc: string;
  cm: number;
  va: string;
  matricula: string;
  ousrinis: string;
  ousrdata: Date;
  ousrhora: string;
  usrinis: string;
  usrdata: Date;
  usrhora: string;
  marcada: boolean;
  dia: Date;
  fechado: boolean;
  inico: string;
  inicio: string;
  fim: string;
  horas: number;
  iklm: number;
  fklm: number;
  klm: number;
  acompanhamento: string;
  relatorio: string;

  no: number;
  linhas: Array<ULDiarios> = [];
  constructor(
    diario: diarios
  ) {
    this.update(diario);
  }
  // actualizar os dados do diario
  update(diario: diarios | any) {
    this.u_rdiariosstamp = diario.u_rdiariosstamp;
    this.cmdesc = diario.cmdesc;
    this.cm = diario.cm;
    this.va = diario.va;
    this.matricula = diario.matricula;
    this.ousrinis = diario.ousrinis;
    this.ousrdata = diario.ousrdata;
    this.ousrhora = diario.ousrhora;
    this.usrinis = diario.usrinis;
    this.usrdata = diario.usrdata;
    this.usrhora = diario.usrhora;
    this.marcada = diario.marcada;
    this.dia = diario.dia;
    this.fechado = diario.fechado;
    this.inico = diario.inico;
    this.fim = diario.fim;
    this.inicio = diario.inicio;
    this.horas = diario.horas;
    this.iklm = diario.iklm;
    this.fklm = diario.fklm;
    this.klm = diario.klm;
    this.acompanhamento = diario.acompanhamento;
    this.relatorio = diario.relatorio;
    this.no = diario.no;

    // inicializar as linhas
  this.linhas = diario.linhas;


  }

  // inserir linhas mas com base  de uma ldiarios
  insertLinha(ldiario: ULDiarios) {
    let linha = new ULDiarios(ldiario);
    linha.u_rdiariosstamp = this.u_rdiariosstamp;
    linha.update(ldiario);
    console.log(linha);
  //  this.linhas.push(linha);
  }


  // adicionar linhas
  addLinha(linha: ULDiarios) {
    console.log(['ULDiarios -+-->', linha]);
    // this.linhas[this.linhas.length+1] = linha;
    this.linhas.push(linha);
    // @todo: emitir alerta da alteração da linha

  }


  // removerliunha
  removeLinha(linha: ULDiarios) {
    this.linhas.splice(this.linhas.indexOf(linha), 1);
  }

  toArray(): any[] {
    return Object.values(this);
  }
}
