<!--Login-->
<section class="bg-full bg-white md:h-screen">
  <img class="bg object-cover h-48 mx-auto w-full animate__animated animate__fadeIn" src="../../assets/Imagens/bg.jpg" alt="bg">
  <img class="bg-small object-cover h-48 mx-auto w-full animate__animated animate__fadeIn mobile-bg" src="../../assets/Imagens/iniciar_sessao_bg.png" alt="bg">
  <div class="bg-form pt-0 flex flex-col items-center justify-center px-6 py-8 mx-auto md:max-h-screen lg:py-0">
      <div class="w-full rounded-lg md:mt-0 sm:max-w-md xl:p-0">
        <img class="logo mx-auto pt-20 w-48 h-auto animate__animated animate__fadeInDown" src="../../assets/Imagens/logo.png" alt="logo">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <form                [formGroup]="signInForm"
              #signInNgForm="ngForm"
              class="m-auto w-64 flex flex-col items-center space-y-4 md:space-y-6 animate__animated animate__fadeIn" >
                  <div class="w-full">
                      <input type="text" id="username"
                      class="placeholder-gray-500 animate__animated animate__fadeIn w-64 bg-gray-100 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2.5 focus:ring-[#b0c950] focus:border-[#b0c950]"
                      placeholder="Nome de Utilizador"  matInput [formControlName]="'username'">
                      <mat-error *ngIf="signInForm.get('username').hasError('required')">
                       Utilizador é obrigatório
                    </mat-error>
                  </div>
                  <div class="w-full flex">
                      <input matInput [type]="hide ? 'password' : 'text'" name="password" id="password"    [formControlName]="'password'"
                      #password placeholder="Palavra-Passe" class="placeholder-gray-500 animate__animated animate__fadeIn w-64 bg-gray-100 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-[#b0c950] focus:border-[#b0c950] block w-full p-2.5" required>
                      <button data-tooltip-target="tooltip-pass" data-tooltip-placement="right" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Esconder Palavra-Passe'" [attr.aria-pressed]="hide">
                         <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                      </button>
                      <div id="tooltip-pass" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip">
                        Alterar Visibilidade de Palavra-Passe
                        <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                  </div>
                  <div class="pt-2">
                  <button   type="button"    *ngIf="signInForm.valid"  (click)="signIn()" [disabled]="!signInForm.valid"
                   (click)="password.type === 'password' ? password.type = 'text' : password.type = 'password'"

                  class="animate__animated animate__fadeInUp w-48 text-white bg-[#955e1b] focus:outline-none focus:bg-[#955e1b] hover:bg-[#b0c950] font-medium rounded text-sm px-5 py-2.5 text-center duration-300">INICIAR SESSÃO</button>
                  </div>
              </form>


          </div>
        </div>

        <div class="text-sm font-bold leading-none text-gray-70">
          <div>{{ version.name }} </div>
          <div>{{ version.hash }} {{ environment.production}} </div>

      </div>
      <!-- Separator -->
      <div class="flex items-center mt-8">
          <div class="flex-auto mt-px border-t"></div>
          <div class="mx-2 text-secondary"> reage lda</div>
          <div class="flex-auto mt-px border-t"></div>
      </div>

  </div>
</section>
