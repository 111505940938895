<!--Upload and Observations-->
<div *ngIf="router.url === '/clientes-pendentes'"
  class="animate__animated animate__fadeIn fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bottom-24 left-1/2">
  <div class="grid h-full max-w-lg grid-cols-2 mx-auto">
    <div class="flex container bg-[#955e1b] rounded-xl w-16 hover:scale-105 duration-75 items-center">
      <button matTooltip="Adicionar Observações" [matTooltipPosition]="'above'"
        (click)="observacoes = true; procurar = false; menu = false" type="button"
        class="Z-30 flex w-full h-full fill-white mx-auto">
        <svg class="w-8 fill-white m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4l0 0 0 0 0 0 0 0 .3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z" />
        </svg>
      </button>
    </div>
    <div class="end-button flex container bg-[#b0c950] rounded-xl w-16 hover:scale-105 duration-75 items-center ml-48">
      <button (click)="showDialog()" matTooltip="Importar Ficheiro" [matTooltipPosition]="'above'" type="button"
        class="Z-30 flex w-full h-full fill-white mx-auto">
        <svg class="w-6 fill-white m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path
            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24V305.9l-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31V408z" />
        </svg>
      </button>
    </div>
  </div>
</div>

<p-dialog header="Importar Ficheiro" [(visible)]="visible" [style]="{width: '80vw'}" class="upload" id="upload"
  tabindex="-1">
  <div class="relative w-full  max-h-full mx-auto">
    <div class="extraOutline p-4 bg-white w-auto bg-whtie m-auto rounded-lg">
      <form class="file_upload p-5 relative border-4 border-dotted border-gray-300 rounded-lg" action="#" method="POST">
        <svg class="text-[#955e1b] w-24 mx-auto mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
        </svg>
        <div class="input_field flex flex-col max-w-xs mx-auto text-center">
          <label>
            <input type="file">
          </label>
          <label class="pt-4">
            <button class="text-sm cursor-pointer w-36 hidden" type="submit"></button>
            <div
              class="text bg-[#955e1b] text-white border border-gray-300 rounded font-medium cursor-pointer p-1 px-3 hover:scale-105 duration-75">
              Importar</div>
          </label>
        </div>
      </form>
    </div>
  </div>
</p-dialog>

<!-- Adicionar Observações -->
<p-sidebar [(visible)]="observacoes" position="top" id="observacoes" aria-label="observacoes" styleClass="observacoes">
  <label for="com" class="pt-6 pl-4 block mb-2 text-sm font-medium text-gray-900">Observações</label>
  <div class="pl-4 pr-4">
    <textarea id="com" rows="4"
      class="pl-6 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-[#955e1b] focus:border-[#955e1b]"
      placeholder="Escreva aqui as suas observações..."></textarea>
  </div>
</p-sidebar>


<!--Edit and Delete-->
<!--
 <div *ngIf="addReport" class="animate__animated animate__fadeIn fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bottom-24 left-1/2">
  <div class="grid h-full max-w-lg grid-cols-2 mx-auto pl-3 pr-12">
    <div class="flex container bg-[#955e1b] rounded-xl w-16 hover:scale-105 duration-75 items-center">
      <button matTooltip="Eliminar" [matTooltipPosition]="'above'" type="button" class="flex w-full h-full fill-white mx-auto">
        <svg class="w-7 m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
      </button>
  </div>
    <div class="end-button flex container bg-[#b0c950] rounded-xl w-16 hover:scale-105 duration-75 items-center ml-48">
      <button matTooltip="Editar" [matTooltipPosition]="'above'" type="button" class="flex w-full h-full fill-white mx-auto">
        <svg class="w-7 m-auto"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
      </button>
    </div>
  </div>
  </div>
-->



<!--Bottom Nav Bar-->
<div
  class="navbar animate__animated animate__fadeIn fixed shadow-xl z-50 w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-xl bottom-4 left-1/2">
  <div class="grid h-full max-w-lg grid-cols-4 mx-auto">
    <button matTooltip="Menu" (click)="menu = true; procurar = false; observacoes = false; adicionar = false"
      (click)="rmenu(this)" aria-controls="default-sidebar" type="button"
      class="inline-flex flex-col items-center justify-center px-5 rounded-l-full hover:bg-gray-50 group hover:rounded-l-full">
      <svg class="w-6 h-6 mb-1 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path
          d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
      </svg>
      <span class="sr-only">Menu</span>
    </button>
    <div class="flex items-center justify-center">
      <button [hidden]="!addReport" matTooltip="Adicionar" (click)="goto()" aria-controls="adicionar" type="button"
        class="inline-flex items-center justify-center w-10 h-10 font-medium bg-[#b0c950] rounded-full scale-125 hover:scale-150 duration-75 group focus:ring-4 focus:ring-[#b0c950] focus:outline-none">
        <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true">
          <path clip-rule="evenodd" fill-rule="evenodd"
            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"></path>
        </svg>
        <span class="sr-only">Adicionar</span>
      </button>
    </div>

    <div class="flex items-center justify-center">
      <button [hidden]="!btSave" matTooltip="Guardar Relatorio" (click)="save()" aria-controls="Guardar" type="button"
        class="inline-flex items-center justify-center w-10 h-10 font-medium bg-[#b0c950] rounded-full scale-125 hover:scale-150 duration-75 group focus:ring-4 focus:ring-[#b0c950] focus:outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
          <path
            d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z" />
        </svg>
        <span class="sr-only">Save</span>
      </button>
    </div>
    <div [hidden]="!btSave" class="flex items-center justify-center">
      <button matTooltip="Procurar" (click)="addCl()" aria-controls="procurar" type="button"
        class="h-full w-full inline-flex flex-col items-center justify-center px-5 rounded-r-full hover:bg-gray-50 group focus:outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M720-400v-120H600v-80h120v-120h80v120h120v80H800v120h-80Zm-360-80q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm80-80h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0-80Zm0 400Z"/></svg>
        <span class="sr-only">Adicionar cliente</span>
      </button>
    </div>




    <div [hidden]="!btCancel" class="flex items-center justify-center">
      <button matTooltip="Cancelar edição" (click)="cancelar()" aria-controls="Cancelar edição" type="button"
        class="h-full w-full inline-flex flex-col items-center justify-center px-5 rounded-r-full hover:bg-gray-50 group focus:outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
          <path
            d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
        </svg>
        <span class="sr-only">Cancelar</span>
      </button>
    </div>



  </div>
</div>

<!--Adicionar-->
<p-sidebar [(visible)]="adicionar" position="top" id="adicionar" class="h-80" aria-label="adicionar">
  <div class="h-full px-3 py-4 overflow-y-auto bg-white">
    <ul class="space-y-2 font-medium">
      <li>
        <a routerLink="novo-relatorio-diario"
          class="flex items-center p-3.5 text-gray-900 rounded-lg hover:bg-[#b1c9501c] hover:text-[#955e1b]">
          <span class="flex-1 ml-3 whitespace-nowrap">Adicionar Relatório Diário</span>
        </a>
      </li>
      <!--(Só para aparecer internamente/administradores)-->
      <li>
        <a routerLink="novo-vendedor"
          class="flex items-center p-3.5 text-gray-900 rounded-lg hover:bg-[#b1c9501c] hover:text-[#955e1b]">
          <span class="flex-1 ml-3 whitespace-nowrap">Adicionar Vendedor</span>
        </a>
      </li>
      <!--
        <li>
           <a href="nova-prova-amostras" class="flex items-center p-3.5 text-gray-900 rounded-lg hover:bg-[#b1c9501c] hover:text-[#955e1b]">
              <span class="flex-1 ml-3 whitespace-nowrap">Adicionar Prova de Amostra</span>
           </a>
        </li>-->
      <!--
        <li>
           <a href="novo-registo-kms" class="flex items-center p-3.5 text-gray-900 rounded-lg hover:bg-[#b1c9501c] hover:text-[#955e1b]">
              <span class="flex-1 ml-3 whitespace-nowrap">Adicionar Registo de Kms Diários</span>
           </a>
        </li>-->
    </ul>
  </div>
</p-sidebar>



<!--Menu Lateral-->
<p-sidebar [(visible)]="menu" position="left" id="default-sidebar" class="h-screen" aria-label="Sidebar">
  <div class="side h-full px-3 py-4 overflow-y-auto bg-white">
    <ul class="space-y-2 font-medium">
      <img class="pl-5 content-start pt-20 w-20 h-auto animate__animated animate__fadeInDown"
        src="../../assets/Imagens/logo.png" alt="logo">
      <li>
        <h2 class="ml-3 pl-5 pt-5">Menu</h2>
      </li>
      <li>
        <a routerLink="/relatorios-diarios"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-[#b1c9501c] hover:text-[#955e1b]">
          <span class="flex-1 ml-3 whitespace-nowrap">Relatórios Diários</span>
        </a>
      </li>

      <li>
        <a routerLink="/listagem-entradas-saidas"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-[#b1c9501c] hover:text-[#955e1b]">
          <span class="flex-1 ml-3 whitespace-nowrap">Listagens Entradas e Saídas</span>
        </a>
      </li>

      <li>
        <a routerLink="/vendedores"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-[#b1c9501c] hover:text-[#955e1b]">
          <span class="flex-1 ml-3 whitespace-nowrap">Vendedores</span>
        </a>
      </li>
      <li class="pt-5">
        <a routerLink="/changelog"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-[#b1c9501c] hover:text-[#955e1b]">
          <span class="">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M400-400h160v-80H400v80Zm0-120h320v-80H400v80Zm0-120h320v-80H400v80Zm-80 400q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z"/></svg>
            Changelog</span>
        </a>
      </li>
      <li class="pt-5">
        <span (click)="logout()"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-[#b1c9501c] hover:text-[#955e1b]">
          <svg aria-hidden="true"
            class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-[#955e1b]"
            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
              clip-rule="evenodd"></path>
          </svg>
          <span class="flex-1 ml-3 whitespace-nowrap">Sair</span>
        </span>
      </li>
    </ul>
  </div>
</p-sidebar>



<!--Procurar-->
<p-sidebar [(visible)]="procurar" position="top" id="procurar" aria-label="procurar" styleClass="procurar">
  <div class="side h-full px-3 py-4 overflow-y-auto bg-white">

    <form>
      <div class="flex">
        <label for="search-dropdown" class="mb-2 text-sm font-medium text-gray-900 sr-only"></label>

        <div class="relative w-full">
          <input type="search" id="search-dropdown"
            class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-300 focus:ring-[#955e1b] focus:border-[#955e1b]"
            placeholder="Pesquise aqui clientes, relatórios, etc... " required>
          <button type="submit"
            class="h-full absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-[#955e1b] rounded-r-lg border-gray-900 hover:bg-[#b0c950] focus:outline-none duration-200">
            <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
            <span class="sr-only">Procurar</span>
          </button>
        </div>
      </div>
    </form>

  </div>
</p-sidebar>
