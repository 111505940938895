import { Component } from '@angular/core';

@Component({
  selector: 'app-app-mcrios',
  templateUrl: './app-mcrios.component.html',
  styleUrls: ['./app-mcrios.component.css']
})
export class AppMcriosComponent {


}
