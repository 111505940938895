import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { of } from 'rxjs';
import { webconfig } from './webconf';
import { reventos } from './reventos';
@Injectable({
  providedIn: 'root'
})



export class McriosService {
  private apirul: string = '';
  private _vendedores:BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _vendedor:BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _diarios:BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _rdiario:BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _ldiario:BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _webconfig:BehaviorSubject<any | null> = new BehaviorSubject(null);
  private __eventos:BehaviorSubject<any | null> = new BehaviorSubject(null);
  private __cliente:BehaviorSubject<any | null> = new BehaviorSubject(null);
  private __upload:BehaviorSubject<any | null> = new BehaviorSubject(null);
  private __stfami:BehaviorSubject<any | null> = new BehaviorSubject(null);
  private __comentarios:BehaviorSubject<any | null> = new BehaviorSubject(null);



  get $vendedores(): Observable<any> {
    return this._vendedores.asObservable();
  }
  get $vendedor(): Observable<any> {
    return this._vendedores.asObservable();
  }
  get $diarios(): Observable<any> {
    return this._diarios.asObservable();
  }
  get $rdiario(): Observable<any> {
    return this._rdiario.asObservable();
  }
  get $ldiario(): Observable<any> {
    return this._ldiario.asObservable();
  }
  get $webconfig(): Observable<any> {
    return this._webconfig.asObservable();
  }
  get $eventos(): Observable<any> {
    return this.__eventos.asObservable();
  }
  get $cliente(): Observable<any> {
    return this.__cliente.asObservable();
  }
  get $upload(): Observable<any> {
    return this.__upload.asObservable();
  }
  get $stfami(): Observable<any> {
    return this.__stfami.asObservable();
  }

  get $comentarios(): Observable<any> {
    return this.__comentarios.asObservable();
  }

  constructor( private _httpClient: HttpClient) {
    this.apirul = environment.apirul;

    // defenições default do webconfig
     const obj: webconfig = {
      showFiller: false,
      procurar: false,
      adicionar: false,
      menu: false,
      observacoes: false,
      addReport: true,
      btCancel: false,
      btSave: false,
      btprocurar: true,

    };
    this.setWebConfig(obj);
    console.log(['defenições default do webconfig ', obj]);
    const reventos: reventos = {
      tipo: '',
      data: []
    };
    this.setEventos(reventos);
   }

/** {{URL}}/api/mcrios/getVendedores
 * listar todos os vendedores
 */
getVendedores(): Observable<any[]> {
  return this._httpClient.get(this.apirul + '/api/mcrios/getVendedores').pipe(
    switchMap((response: any) => {
      console.log(['getVendedores', response]);
      this._vendedores.next(response);
      return of(response);
    }
  ) );
}


getVendedor(no): Observable<any[]> {
  return this._httpClient.get(this.apirul + '/api/mcrios/vendedor/'+ no).pipe(
    switchMap((response: any) => {
      console.log(['getVendedores', response]);
      this._vendedor.next(response);
      return of(response);
    }
  ) );
}
/** {{URL}}/api/mcrios/getRelatoriosDiarios
 * listar todos os relatorios diarios
 * @param obj
 * @param search
 * @returns
 *
 */
getRelatoriosDiarios (obj:any =  [] , search =''): Observable<any[]> {
  return this._httpClient.post(this.apirul + '/api/mcrios/Diarios', obj ).pipe(
    switchMap((response: any) => {
      console.log(['getVendedores', response]);
      this._diarios.next(response);
      return of(response);
    }
  ) );
}


/** {{URL}}/api/mcrios/getDiario/{{u_rdiariosstamp}}
 * listar todos os relatorios diarios
 *
 */
getDiario (u_rdiariosstamp): Observable<any[]> {
  return this._httpClient.get(this.apirul + '/api/mcrios/getDiario/'+ u_rdiariosstamp).pipe(
    switchMap((response: any) => {
      console.log(['getDiario', response]);
      this._rdiario.next(response);
      return of(response);
    }
  ) );
  }


  newDiario (): Observable<any[]> {
    return this._httpClient.get(this.apirul + '/api/mcrios/newDiario').pipe(
      switchMap((response: any) => {
        console.log(['newDiario', response]);
        this._rdiario.next(response);
        return of(response);
      }
    ) );
  }
  familias (): Observable<any[]> {
    return this._httpClient.get(this.apirul + '/api/phc/getStFami').pipe(
      switchMap((response: any) => {
        console.log(['stfami', response]);
        this.__stfami.next(response);
        return of(response);
      }
    ) );
  }
  saveDiario (obj): Observable<any[]> {
    return this._httpClient.post(this.apirul + '/api/mcrios/saveDiario', obj).pipe(
      switchMap((response: any) => {
        console.log(['saveDiario', response]);
        this._rdiario.next(response);
        return of(response);
      }
    ) );
  }

  setWebConfig (obj:webconfig): Observable<webconfig> {
    console.log(['setWebConfig', obj]);
    this._webconfig.next(obj);
    return of(obj);
  }

  getWebConfig (): Observable<webconfig> {
    return this._webconfig.asObservable();
  }

  setEventos (obj:reventos): Observable<reventos> {
    console.log(['setEventos', obj]);
    this.__eventos.next(obj);
    return of(obj);
  }
  getEventos (): Observable<reventos> {
    return this.__eventos.asObservable();
  }


  setCliente (obj:any): Observable<any> {
    console.log(['setCliente', obj]);
    this.__cliente.next(obj);
    return of(obj);
  }
  getCliente (search:string = '' ): Observable<any[]> {
    return this._httpClient.post(this.apirul + '/api/mcrios/getClientes',  {pesquisa: search}).pipe(
      switchMap((response: any) => {
        console.log([' getCliente ', response]);
        this.__cliente.next(response);
        return of(response);
      }
    ) );
  }


saveComentario (obj): Observable<any[]> {
  return this._httpClient.post(this.apirul + '/api/mcrios/saveComentario', obj).pipe(
    switchMap((response: any) => {
      console.log(['saveComentario', response]);
      this.__comentarios.next(response);
      return of(response);
    }
  ) );
}

  Uploadfiles (postForm): Observable<any[]> {
    return this._httpClient.post(this.apirul + '/api/mcrios/UploadFile', postForm).pipe(
      switchMap((response: any) => {
        console.log([' __upload', response]);
        this.__upload.next(response);
        return of(response);
      }
    ) );
  }


  }
