import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { BrowserModule } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { codigoPostalValidator, nifValidator }  from './validators';

@Component({
  selector: 'app-add-cliente-dialog',
  templateUrl: './add-cliente-dialog.component.html',
  styleUrls: ['./add-cliente-dialog.component.css'],
  standalone: true,
  imports: [ SharedModule, MatDialogModule,ReactiveFormsModule,CommonModule,MatFormFieldModule, MatInputModule ]
})
export class AddClienteDialogComponent {
  clienteForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddClienteDialogComponent>,
    private fb: FormBuilder
  ) {
    this.clienteForm = this.fb.group({
      nome: ['', Validators.required],
      email: ['', Validators.email],
      telefone: ['', Validators.required],
      morada: [''],
      localidade: [''],
      codigoPostal: ['', [Validators.required, codigoPostalValidator()]],
      nif: ['', nifValidator()],
      notas: ['']
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.clienteForm.valid) {
      this.dialogRef.close(this.clienteForm.value);
    }
  }
}
