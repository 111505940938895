import { Event } from './../../../node_modules/undici-types/patch.d';

import { webconfig } from './../core/app/webconf';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { McriosService } from './../core/app/mcrios.service';
import { Component, OnInit, OnDestroy, SimpleChanges, OnChanges, ViewEncapsulation, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule, FormArray } from '@angular/forms';
import { NgIf, ViewportScroller } from '@angular/common';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, map, startWith, switchMap, throwError } from 'rxjs';
import { Renderer2, ElementRef } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder } from "@angular/forms";
import { URDiarios } from '../core/app/URDiarios';
import { ULDiarios } from '../core/app/ULDiarios';
import { on } from 'events';
import { Select2Data } from 'ng-select2-component';
import { VoiceRecognitionService } from '../voice-recognition.service';
import { AddClienteDialogComponent } from '../add-cliente-dialog/add-cliente-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Console } from 'console';
/**
 * Component for creating a new daily report.
 * @remarks
 * This component allows the user to create a new daily report, add lines to it, and save it.
 * It also includes a map for geolocation and a search bar for selecting clients.
 * @packageDocumentation
 */
@Component({

  selector: 'app-novo-rel-diario',
  templateUrl: './novo-rel-diario.component.html',
  styleUrls: ['./novo-rel-diario.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class NovoRelDiarioComponent implements OnInit, OnDestroy, OnChanges {

  selectedFile: any = null;
  data: Select2Data = [];
  vendaradio: any;
  cobrancaradio: any;
  divs: number[] = [];
  $rfiles = [];
  public lat = 0;
  public lng = 0;
  URDiarios: URDiarios;
  myControl = new FormControl();
  options = [];
  peopleLoading = false;
  filteredOptions = new BehaviorSubject<any>([]);
  familias = new BehaviorSubject<any>([]);
  data$: Observable<string> = this.filteredOptions.asObservable();
  userForm: FormGroup;
  fields: any;
  vprodutos: any;
  onEdit: boolean = false;
  center: google.maps.LatLngLiteral;
  // produtos;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 40.763111724333235, lng: -8.577399143979395 },  //40.763111724333235, -8.577399143979395
    zoom: 14,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,

  };
  status: "initial" | "uploading" | "success" | "fail" = "initial";
  files: File[] = [];
  @ViewChild('obprodutos') obprodutos: ElementRef;
  campos = this.fb.group({
    localidade: this.fb.control('', []),
    contacto: this.fb.control('', []),
    fisico: this.fb.control('', []),
    tipo: this.fb.control('', []),
    tel: this.fb.control('', []),
    obs: this.fb.control(''),
    com: this.fb.control(''),
    venda: this.fb.control(''),
    cobranca: this.fb.control(''),
    email: this.fb.control(''),
    morada: this.fb.control(''),
    codigoPostal: this.fb.control(''),
    comentarios: this.fb.control(''),
    nome: this.fb.control('', []),
    latitude: this.fb.control('', []),
    longitude: this.fb.control('', []),
    produtos: this.fb.control('', []),
    fim: this.fb.control('', []),
    inicio: this.fb.control('', []),
    ausente: this.fb.control(false),
    isposvenda: this.fb.control(false),
    isreclamacao: this.fb.control(false),
    iscobranca: this.fb.control(false),
    isvenda: this.fb.control(false),
    encomenda: this.fb.control(false),
  });
  constructor(
    private _router: Router,
    private _McriosService: McriosService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
    private fb: FormBuilder,
    public service: VoiceRecognitionService, private scroller: ViewportScroller,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog

  ) {

    this.URDiarios = new URDiarios({});
    this.service.init()
    let arrLinhas = [];

console.log('this.URDiarios', this.URDiarios)

    this.userForm = this.fb.group({
      inicio: this.fb.control(this.URDiarios.inico, []),
      fim: this.fb.control(this.URDiarios.fim, []),
      relatorio: this.fb.control(this.URDiarios.relatorio, []),
      iklm: this.fb.control(this.URDiarios.iklm, []),
      fklm: this.fb.control(this.URDiarios.fklm, []),
      acompanhamento: this.fb.control(this.URDiarios.acompanhamento, []),
      visitas:
      // this.fb.group({
  this.campos
     // }),
    });



    this.patch();



  }

  startService() {
    this.service.start()
  }

  stopService() {
    this.service.stop()
  }


  testech(event) {
    console.log(event);
  }

  createCL(): void {
    if (!this.onEdit) {



      this.onEdit = true;
      console.log('---->', this.URDiarios)
      const csl: ULDiarios = new ULDiarios({ u_rdiariosstamp: this.URDiarios.u_rdiariosstamp });
      this.getLocation();

      this.URDiarios.addLinha(csl);
      const siz = this.URDiarios.linhas.length;
      if (this.lat > 0 && this.lng > 0 && siz > 0) {
        this.URDiarios.linhas[siz - 1].latitude = this.lat;
        this.URDiarios.linhas[siz - 1].longitude = this.lng;
      }
      console.log('comentarios' + (siz - 1))
      //   this.userForm.addControl('comentarios' + (siz - 1), this.fb.control(''));
      //  this.userForm.addControl('localidade' + (siz - 1), this.fb.control(''));
      console.log('controls', this.userForm.controls)
      console.log('this.nome', this.nome)
      /* this.filteredOptions = this.nome.valueChanges.pipe(
         startWith(''),
         debounceTime(400),
         distinctUntilChanged(),
         switchMap(val => {
           console.log(['switchMap', val]);
           return this.filter(val || '')
         })
       )*/
      this.nome.valueChanges.subscribe((data: any) => {
        console.log(['nome.valueChanges', data]);
        this.filter(data || '').subscribe((ddata: any) => {
          console.log(['filter s', ddata, this.options]);
          this.filteredOptions.next(ddata);
        }, (error) => {
          console.log(['error', error]);
        }
          , () => {
            console.log('complete');
          }
        );
        this.scroller.scrollToAnchor('nCL');

      })

      //key('comentarios',div,i)

      //this.patch();
      const control = <FormArray>this.userForm.get('visitas');
      if (control.length > 0) {
        control.push(this.campos);
        console.log('Campos de cliente ', this.campos);

      }

      //this.rdiario[]update({ 'linhas': [...this.rdiario.linhas, { 'local': '', 'contacto': '', 'fisico': '', 'tel': '', 'obs': '', 'com': '', 'venda': '', 'cobranca': '' }] });
      //   this.divs.push(this.divs.length);

      console.log(['createCL', this.URDiarios]);
      const element = this.el.nativeElement;
      element.scrollTop = element.scrollHeight;
      // notificar a navbar que foi adicionado uma linha


    } else {

      // Swal.fire(

      // notificar  que apenas editamos uma linha de cada vez

      setTimeout(() => {

        this.scroller.scrollToAnchor('nCL');
      }, 1000);
      // this.router.navigate(['/mcrios']);

    }
  }
  tipoContacto: any;
  objetivo: any;
  rdiario: Observable<URDiarios>;
  //ldiario: Observable<any[]>;



  inicio = new FormControl('', []);
  fim = new FormControl('', []);
  iklm = new FormControl('', []);
  fklm = new FormControl('', []);
  acompanhamento = new FormControl('', []);
  comentarios = new FormControl('', []);
  numCliente = new FormControl('', []);
  relatorio = new FormControl('', []);
  nomeCliente = new FormControl('', []);
  local = new FormControl('', []);
  contacto = new FormControl('', []);
  tipo = new FormControl(1, []);
  objectivo = new FormControl(1, []);
  tel = new FormControl('', []);
  obs = new FormControl('', []);
  com = new FormControl('', []);
  localidade = new FormControl('', []);
  localidade0 = new FormControl('', []);
  venda = new FormControl('', []);
  cobranca = new FormControl('', []);
  encomenda = new FormControl('', []);
  nome = new FormControl('', []);
  latitude = new FormControl('', []);
  longitude = new FormControl('', []);
  isVenda = new FormControl('', []);
  isReclamacao = new FormControl('', []);
  isCobranca = new FormControl('', []);
  isPosVenda = new FormControl('', []);
  produtos = new FormControl('', []);
  emailcc = new FormControl('', []);
  mailList: string[] = ['carlos@reage.pt', 'isabelhenriques@mcrios.pt', 'carlosrios@mcrios.pt ', 'leonorios@mcrios.pt'];
  bcc = ''
  loadDados(dados) {
    console.dir(dados)
    /*
        dados = {
          "u_rdiariosstamp": "REA98239693A653914cd13e68",
          "cmdesc": "Carlos Rios",
          "cm": 1,
          "va": "",
          "matricula": "20-16-VR",
          "ousrinis": "REA",
          "ousrdata": "2023-10-25 13:14:53.080",
          "ousrhora": "13:14:53",
          "usrinis": "REA",
          "usrdata": "2023-10-25 13:14:53.080",
          "usrhora": "13:14:53",
          "marcada": false,
          "dia": "2023-10-25T13:14:53+00:00",
          "fechado": false,
          "inico": "13:14",
          "fim": "13:14",
          "horas": 0,
          "iklm": 0,
          "fklm": 0,
          "klm": "0",
          "acompanhamento": "fff",
          "relatorio": "",
          "no": "3",
          "linhas": []
        }
    */
    // constade do dia, no formato yyyy-MM-dd
    const dia = dados.dia.substring(0, 10);
    // vamos criar a data de inico e fim, baseado no dia e no compo inicio e fim
    const inicio = dia + 'T' + dados.inico + ':00+00:00';
    const fim = dia + 'T' + dados.fim + ':00+00:00';
    // dados.inicio = inicio;
    // dados.fim = fim;

    this.rdiario = dados;
    console.log('loadDados', this.rdiario);
    // convert the string "2023-10-25T13:14:53+00:00" to date in the format "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    const d = new Date(inicio);
    console.log(d);
    console.log(dados);
    console.log(['inicio', d]);
    //this.userForm.controls['inicio'].patchValue(d);
    // this.userForm.controls['fim'].patchValue(d);
    //this.inicio.setValue('10:10')
    // this.fim.setValue(dados.fim);
    //this.iklm.setValue(dados.iklm);
    //this.fklm.setValue(dados.fklm);
    //this.acompanhamento.setValue(dados.acompanhamento);
    //this.relatorio.setValue(dados.relatorio);
    // vamos actualizar  o campo inicio que é uma data tem de ser convertido para string
    this.rdiario['inicio'] = new Date(inicio);
    this.rdiario['fim'] = new Date(fim);
    this.URDiarios.dia = dia;
    this.URDiarios.inico = this.rdiario['inicio'];
    this.URDiarios.fim = fim;
    this.rdiario['u_rdiariosstamp'] = dados.u_rdiariosstamp;
    this.rdiario['cmdesc'] = dados.cmdesc;
    this.rdiario['cm'] = dados.cm;
    this.rdiario['va'] = dados.va;
    this.rdiario['matricula'] = dados.matricula;
    this.rdiario['ousrinis'] = dados.ousrinis;
    this.rdiario['ousrdata'] = dados.ousrdata;
    this.rdiario['ousrhora'] = dados.ousrhora;
    this.rdiario['usrinis'] = dados.usrinis;
    this.rdiario['usrdata'] = dados.usrdata;
    this.rdiario['usrhora'] = dados.usrhora;
    this.rdiario['marcada'] = dados.marcada;
    this.rdiario['dia'] = dados.dia;
    this.rdiario['fechado'] = dados.fechado;
    this.rdiario['inico'] = dados.inico;
    this.rdiario['fim'] = dados.fim;
    this.rdiario['horas'] = dados.horas;
    this.rdiario['iklm'] = dados.iklm;
    this.rdiario['fklm'] = dados.fklm;
    this.rdiario['klm'] = dados.klm;
    if (dados.fklm == 0) {
      this.rdiario['fklm'] = dados.iklm;
      dados.fklm = dados.iklm;
    }

    this.rdiario['acompanhamento'] = dados.acompanhamento;
    this.rdiario['relatorio'] = dados.relatorio;
    this.rdiario['no'] = dados.no;
    this.rdiario['linhas'] = dados.linhas;  // vamos carregar as linhas
    //this.rdiario['linhas'] = [] // vamos carregar as linhas
    dados.linhas.forEach(element => {
      console.log('fff-->', element);
      //this.rdiario['linhas'].push( new ULDiarios(element));
    });
    this.URDiarios.update(this.rdiario);

    this.$rfiles = [];
    console.log('FIM DE loadDados ', this.rdiario)
    this.onEdit = false;
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    const webconfig: webconfig = {
      showFiller: false,
      procurar: false,
      adicionar: false,
      btprocurar: true,
      menu: false,
      observacoes: false,
      btCancel: false,
      btSave: false,
      addReport: true
    };
    this._McriosService.setWebConfig(webconfig);
  }
  ngOnInit() {

    /** defenições do userForm */
    this.fields =
    {

      type: {
        options: [

        ]



      }
    }
      ;


    console.log('ngOnInit');
    this.route.params.subscribe(params => {
      const id = params['id'];
      this._McriosService.getDiario(id).subscribe((data: any) => {
        console.log(['dados do relatorio data', data]);
        //this.rdiario = data;
        this.loadDados(data);
        // this.cdRef.markForCheck();
      }, (error) => {
        console.log(['error', error]);
      });
    });
    const webconfig: webconfig = {
      showFiller: false,
      procurar: false,
      adicionar: false,
      menu: false,
      observacoes: false,
      btCancel: false,
      btSave: true
    };
    this._McriosService.setWebConfig(webconfig);

    // vamos subscrever o evento as observações
    this._McriosService.getEventos().subscribe((data: any) => {
      console.log(['getEventos() - subscribe()', data]);
      switch (data.tipo) {
        case 'addCl':
          this.createCL();
          break;
        case 'saveReport':
          this.saveReport();
          break;
        default:
          break;
      }
    }
      , (error) => {
        console.log(['error', error]);
      }
      , () => {
        console.log('complete');
      }
    );
    this._McriosService.familias().subscribe((data: any) => {
      console.log(['getStfami()', data]);
      this.data = [];
      data.forEach(fam => {
        this.data.push({ value: fam.ref, label: fam.nome });
      });

      this.familias = data;
      //   this.familias.next(data);
    }
      , (error) => {
        console.log(['error', error]);
      }
      , () => {
        console.log('complete');
      }
    );


    let map;
    async function initMap(): Promise<void> {
      // The location of Uluru
      const position = { lat: -25.344, lng: 131.031 };

      // Request needed libraries.
      //@ts-ignore
      const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

      // The map, centered at Uluru
      /*
      map = new Map(
        document.getElementById('map') as HTMLElement,
        {
          zoom: 4,
          center: position,
          mapId: 'DEMO_MAP_ID',
        }
      );

      // The marker, positioned at Uluru
      const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
        title: 'Uluru'
      });

      */
      //  alert('ddd')
    }

    initMap();

  }


  isNumeric(control: FormControl) {
    const value = control.value;

    if (isNaN(value) || typeof value !== 'number') {
      return { notNumeric: true }; // Retorna um erro se não for numérico
    }

    return null; // Retorna nulo se for numérico
  }

  saveReport() {
    if (this.ValidateSaveDiario()) {

      Swal.fire({
        title: 'Relatorio Diario id: ' + this.rdiario['no'] + ' ?',
        text: " do dia " + this.rdiario['dia'].substring(0, 10),
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#cecece',
        confirmButtonText: 'Guardar',

        denyButtonText: 'Fechar Relátorio',
      }).then((result) => {
        if (result.isConfirmed || result.isDenied) {

          const formData = new FormData();
          if (this.files.length) {

            [...this.files].forEach((file) => {
              formData.append("file", file, file.name);
            });
          }

          //  const upload$ = this._McriosService.Uploadfiles(formData);

          //       this.status = "uploading";
          this._McriosService.saveDiario({ diario: this.URDiarios, result: result, coordenadas: { lat: this.lat, lng: this.lng }, files: this.$rfiles, produtos: this.vprodutos, email: this.bcc }).subscribe((data: any) => {
            console.log(['saveReport()', data]);
            //  this.rdiario = data;
            //  this.loadDados(data); // vamos carregar os dados
            Swal.fire(
              'Enviado!',
              'O relatorio Foi Guardado ! ',
              'success'
            )
            this.onEdit = false;
            // vamos navegar novamente para esta pagina usando o roter
            //   this._router.navigateByUrl('/novo-relatorio-diario/'+ data.u_rdiariosstamp,{ skipLocationChange: true, replaceUrl: false,onSameUrlNavigation: 'reload' });
            // reload da pagina usando o javacript window
            console.log('goto() - newDiario()');
            this._router.navigate(['/mcrios']);

          }, (error) => {
            console.log(['error', error]);

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Erro ao guardar o relatorio! (API)',

            })
          }
            , () => {
              console.log('complete');
              Swal.fire(
                'Enviado!',
                'O relatorio Foi Guardado ! ',
                'success'
              )
            }
          );
        }
      }
      );
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude +
            "Longitude: " + position.coords.longitude);
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.center = { lat: this.lat, lng: this.lng };
          this.mapOptions.center.lat = this.lat;
          this.mapOptions.center.lng = this.lng;
          this.mapOptions.zoom = 15;
          console.log(this.lat);
          console.log(this.lng);
          console.log('https://www.google.pt/maps/@' + this.lat + ',' + this.lng + ',15.5z?entry=ttu');
        }
      },
        (error) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }


  filter(val: string): Observable<any[]> {

    console.log(['filter', val]);
    this.peopleLoading = true;
    console.log(['getCliente()', typeof val]);

    return this._McriosService.getCliente(val).pipe(map((data: any) => {
      console.log(['getCliente()', data]);
      this.peopleLoading = false;
      this.options = data;
      return data;
    })) as Observable<any[]>;

  }


  delDiv(i) {
    console.log(['delDiv', i]);
    this.URDiarios.removeLinha(i);
    this.onEdit = false;
  }

  submit(obForm) {
    console.log("Submit", obForm)
  }




  patch() {

    const control = <FormArray>this.userForm.get('visitas');
    console.log(['patch - control', this.fields]);
    if (control.length > 0) {
      //   this.fields.type.options.forEach(x => {
      //  control.push(this.patchValues(x.label, x.value))
      // })
    }
  }

  patchValues(label, value) {
    return this.fb.group({
      label: [label],
      value: [value]
    })
  }

  key(cmp, i) {
    console.log(['key --> ', cmp + '' + i]);

    return cmp + '' + i
  }
  getTipoDaPropriedadeLinhas(fthis): string {
    //  console.dir(fthis)
    return typeof fthis;
  }

  revento(id, tipo, $event) {
    console.log('revento', id, tipo, $event)
    switch (tipo) {
      case 'change':
        this.selecionaCL(id, $event)
        break;
      case 'search':
        // this.selecionaCL(id, {});
        this.filteredOptions.next(

          this._McriosService.getCliente($event.term).subscribe((data: any) => {
            console.log(['getCliente()', data]);
            this.options = data;
            this.peopleLoading = false;
            return data;
          }, (error) => {
            console.log(['error', error]);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Erro ao procurar o cliente! (API)',

            })

          }
          ))

        break;

      default:
        break;
    }
  }
  selecionaCL(linha, cliente) {
    if (cliente !== null && cliente !== undefined) {
      console.log(['selecionaCL', linha, cliente]);
      this.URDiarios.linhas[linha].nome = cliente.nome;
      this.URDiarios.linhas[linha].localidade = cliente.localidade;
      this.URDiarios.linhas[linha].contacto = cliente.TELEFONE + cliente.TLMVL;
      this.URDiarios.linhas[linha].no = cliente.no;
      this.URDiarios.linhas[linha].agno = Number(cliente.AGNO);

      this.URDiarios.linhas[linha].localidade = cliente.localidade;
      this.URDiarios.linhas[linha].longitude = cliente.longitude;
      this.URDiarios.linhas[linha].latitude = cliente.latitude;

      this.URDiarios.linhas[linha].estab = cliente.estab;

      console.info('selecionaCL', this.URDiarios.linhas[linha]);
      this.scroller.scrollToAnchor('nCL');
    }
    else {
      console.log(['selecionaCL sem cliente !!! ', linha, cliente]);
    }

  }

  devLinhaOnDB(i) {
    this.URDiarios.linhas[i].marcada = true;
    console.log(['devLinhaOnDB', i]);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('ngOnChanges', changes)
  }

  toggleVisibility(i) {
    const element = document.getElementById(`vis_${i}`);
    if (element) {
      element.classList.toggle('hidden');
    }
  }


  /** validar saveDiario */

  ValidateSaveDiario(): boolean {
    //vamos validar dados do relatorio e das linhas e se estiver tudo ok vamos guardar
    // se não vamos mostrar uma mensagem de erro usando o sweetalert2

    // validar os dados do relatorio
    console.log('validar os dados do relatorio', this.URDiarios);

    if (this.URDiarios.fim == null) {

      return false;
    }
    if (this.URDiarios.iklm == null || this.URDiarios.iklm == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'O campo inicio km de inicio não  pode ser nulo ou zero !',

      })
    }

    // o inicio e o fim tem de ser diferentes e o inicio tem de ser menor que o fim
    if (this.URDiarios.inico === this.URDiarios.fim) {
      console.log('inicio', this.URDiarios.inico, 'fim', this.URDiarios.fim);

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'O campo inicio e fim tem de ser diferentes !',

      })
      return false;
    }

    //  return false;


    if (this.URDiarios.fklm == null || this.URDiarios.fklm == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'O campo fim km de fim não  pode ser nulo ou zero !',

      })
      return false;
    }
    // o
    // validar os dados das linhas
    // alert(' numero de linhas ' + this.URDiarios.linhas.length);
    if (this.URDiarios.linhas.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Não existem Visitas para guardar !',

      })
      return false;
    }
    // validar os dados das  o nome do cliente tem de estar preenchido
    console.log('validar os dados das linhas', this.URDiarios.linhas);
    for (let i = 0; i < this.URDiarios.linhas.length; i++) {
      if (this.URDiarios.linhas[i].nome == null || this.URDiarios.linhas[i].nome == '') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Não encontro o cliente',

        })
        return false;
      }
      // o tipo de contacto tem de estar preenchido
      if (this.URDiarios.linhas[i].tipo == null || this.URDiarios.linhas[i].tipo == 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'O tipo de contacto tem de estar preenchido !',

        })
        return false;
      }

      // pelo menos uma destas opções pode estar activa
      if (!(this.URDiarios.linhas[i].isvenda || this.URDiarios.linhas[i].iscobranca || this.URDiarios.linhas[i].isreclamacao || this.URDiarios.linhas[i].isposvenda) && Number(this.URDiarios.linhas[i].tipo) !== 3) {
        console.log('inicio', this.URDiarios.linhas[i]);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Tem de seleccionar pelo menos uma das opções: venda, cobrança, reclamação ou pos venda !',

        })
        return false;
      }

      // inicio e fim tem de ser diferentes e o inicio tem de ser menor que o fim
      if (this.URDiarios.linhas[i].u_ldiariosstamp === '') {
        if (this.URDiarios.linhas[i].inicio === this.URDiarios.linhas[i].fim
        ) {
          console.log('inicio', this.URDiarios.linhas[i]);
         //    alert('inicio' + this.URDiarios.linhas[i].inicio + ' fim ' + this.URDiarios.linhas[i].fim);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'A hora de inicio e fim tem de ser diferentes !',

          })
          return false;
        }
        // o inicio tem de ser maior que o fim
        if (this.URDiarios.linhas[i].inicio > this.URDiarios.linhas[i].fim) {
          console.log('inicio', this.URDiarios.linhas[i].inicio)
          console.log('Fim', this.URDiarios.linhas[i].fim);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'A hora de inicio tem de ser menor que a hora de fim !',

          })
          return false;

        }
      }

// este for a  ultima linha
if (i == this.URDiarios.linhas.length - 1) {
      console.log('as datas tem de estar preenchidas ');
      console.log('inicio', this.URDiarios.linhas[i].inicio )
      console.log('Fim', this.URDiarios.linhas[i].fim );
      let rinicio = String(this.URDiarios.linhas[i].inicio);
      let rfim = String(this.URDiarios.linhas[i].fim);
       // o rinicio e o rfim tem de estar preenchidos e estar no formato NN:NN
       console.log('rinicio', rinicio , 'rfim', rfim , 'tipo', typeof rinicio , 'tipo', typeof rfim);

       // valudar se rfim e rinicio são do tipo string e se tem o formato NN:NN
        if (typeof rinicio !== 'string' || typeof rfim !== 'string' || rinicio.length !== 5 || rfim.length !== 5) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'As horas de inicio e fim tem de estar preenchidas e no formato HH:MM !',

          })
          return false;
        }
        // validar se as horas de inicio e fim são validas
        let h = Number(rinicio.substring(0, 2));
        let m = Number(rinicio.substring(3, 5));
        if (h < 0 || h > 23 || m < 0 || m > 59) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'A hora de inicio tem de ser valida !',

          })
          return false;
        }
        h = Number(rfim.substring(0, 2));
        m = Number(rfim.substring(3, 5));
        if (h < 0 || h > 23 || m < 0 || m > 59) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'A hora de fim tem de ser valida !',

          })
          return false;
        }


}

    }
    // o tipo de contacto tem de estar preenchido






console.log({ diario: this.URDiarios, coordenadas: { lat: this.lat, lng: this.lng }, files: this.$rfiles, produtos: this.vprodutos, email: this.bcc } ) ;
//    return false;
    return true;

  }

  onFileSelected(event) {
    console.debug(event);
    const files = event.target.files;

    if (files.length) {
      this.status = "initial";
      this.files = files;
      this.onUpload();
    }
  }

  onUpload() {
    if (this.files.length) {
      const formData = new FormData();
      let i = 0;
      [...this.files].forEach((file) => {
        //  alert( file.name + '  ' +  file.size )
        formData.append("file[]", file, file.name);
        // formData.append("file[]", file, file.name);
        i++;
      });
      console.log('onUpload', formData, this.files)
      const upload$ = this._McriosService.Uploadfiles(formData);

      this.status = "uploading";

      upload$.subscribe({
        next: (rfiles) => {
          console.log('File Uploaded', rfiles);
          this.status = "success";
          rfiles.forEach(element => {
            this.$rfiles.push(element);

          });
        },
        error: (error: any) => {
          this.status = "fail";
          return throwError(() => error);
        },
      });
    }
  }

  SelectUpdate(event) {
    console.log('SelectUpdate', event)
    this.vprodutos = event.value;
  }


  formatLocalidade(localidade: string): string {
    // Remove quebras de linha e retorna os primeiros 60 caracteres
    return localidade.replace(/(\r\n|\n|\r)/gm, "").substring(0, 60);
  }

  onSelectionChange() {
    // Remover o foco do elemento select2
    if (this.obprodutos && this.obprodutos.nativeElement) {
      this.obprodutos.nativeElement.blur();
    }
  }

  async sendBcc(i) {
    const { value: formValues } = await Swal.fire({
      title: "Enviar Cópia para:",
      html: `
      <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px;">
      <div style="text-align: left;">
        <input type="checkbox" name="mailbcc" value="carlosrios@mcrios.pt" id="carlosrios" />
        <label for="carlosrios">Carlos Rios</label>
      </div>

      <div style="text-align: left;">
        <input type="checkbox" name="mailbcc" value="Leonorrios@mcrios.pt" id="leonorrios" />
        <label for="leonorrios">Leonor Rios</label>
      </div>

      <div style="text-align: left;">
        <input type="checkbox" name="mailbcc" value="isabelhenriques@mcrios.pt" id="isabelhenriques" />
        <label for="isabelhenriques">Isabel Henriques</label>
      </div>

      <div style="text-align: left;">
        <input type="checkbox" name="mailbcc" value="carlos@reage.pt" id="carlosreage" />
        <label for="carlosreage">Carlos - Reage</label>
      </div>
      <div style="text-align: left;">
      <input type="checkbox" name="mailbcc" value="pedro@reage.pt" id="carlosreage" />
      <label for="carlosreage">Miguel(pedro) - Reage</label>
    </div>
    </div>
      `,
      focusConfirm: false,
      preConfirm: () => {
        const checkboxes = document.querySelectorAll('input[name="mailbcc"]:checked');
        const values = Array.from(checkboxes).map((checkbox) => (checkbox as HTMLInputElement).value);
        return values;
      }
    });
    if (formValues) {
      //Swal.fire(JSON.stringify(formValues));
      this.bcc = formValues.join(';');
    }
  }


  addCliente(i) {
    console.log('addCliente', i);
    console.log('addCliente', i);
    const dialogRef = this.dialog.open(AddClienteDialogComponent, {
       width: 'calc(100vw - 20px)'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      /**
       * codigoPostal
:
"3850593"
email
:
"Carlos@reage.pt"
localidade
:
"Albergaria"
morada
:
"Rua prof. egas moniz"
nif
:
"1235135365563"
nome
:
"Carlos Fernandes"
notas
:
"21235112454353405304"
telefone
:
"918867789"
       */

      this.URDiarios.linhas[i].no = 0;
      this.URDiarios.linhas[i].nome = result.nome;
      this.URDiarios.linhas[i].localidade = result.localidade;
      this.URDiarios.linhas[i].nif = result.nif;
      this.URDiarios.linhas[i].email = result.email;
      this.URDiarios.linhas[i].morada = result.morada;
      this.URDiarios.linhas[i].codigoPostal = result.codigoPostal;
      this.URDiarios.linhas[i].contacto = result.telefone;
      this.URDiarios.linhas[i].obs = result.notas;
      this.URDiarios.linhas[i].agno = -1 ; // para indicar que é um novo cliente

this.cdRef.detectChanges();
    });
  }


}








