<app-navbar></app-navbar>
<nav class="container max-w-screen bg-white border-gray-200 px-4 py-3 mx-auto top-bar">
  <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen pt-4 pb-4">
      <a routerLink="/mcrios" class="inline-flex items-center">
        <svg class="w-6 animate__animated animate__fadeInRight" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
        <h1 class="animate__animated animate__fadeIn titulo-top items-center pl-3">Changelog</h1>
      </a>
  </div>
</nav>

<section class="container mx-auto mt-10">
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4" >
    <div class="bg-white rounded-lg shadow-lg overflow-hidden" >
      <div class="p-4">
        <h2 class="text-lg font-bold mb-2">Inserção de relatorios</h2>
        <p class="text-gray-700 text-base">Possibiliade de adicionar Anexos (fotos) nas reclamações</p>
        <p class="text-gray-700 text-base">Copia dos relatórios por email </p>
        <p class="text-gray-700 text-base">Possibilidade de referenciar os produtos(familias phc) </p>
        <p class="text-gray-700 text-base">Pedida por: Margarida| Isabel Henriques</p>
        <p class="text-gray-700 text-base">Data de disponibilização: 04/12/2023</p>
      </div>
      <div class="p-4 bg-gray-100">
        <video class="w-full" autoplay loop muted>
          <source src="/assets/vd/1.webm" type="video/webm">
        </video>
      </div>
    </div>
    <div class="bg-white rounded-lg shadow-lg overflow-hidden" >
      <div class="p-4">
        <h2 class="text-lg font-bold mb-2">Comentarios</h2>
        <p class="text-gray-700 text-base">permite comentar os relatorios, </p>
        <p class="text-gray-700 text-base">Pedida por: carlos&#64;reage</p>
        <p class="text-gray-700 text-base">Data de disponibilização: 04/12/2023</p>
      </div>
      <div class="p-4 bg-gray-100">
        <video class="w-full" autoplay loop muted>
          <source src="/assets/vd/2.webm" type="video/webm">
        </video>
      </div>
    </div>
  </div>
</section>



