<!-- Error Message -->
<div class="pt-2 erro" id="erro">
  <svg class="inline-flex w-4 fill-red-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256
  512A256 256 0 1 0 256
  0a256 256 0 1 0 0 512zm0-384c13.3
  0 24 10.7 24 24V264c0 13.3-10.7
  24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32
  32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
 <!-- <span class="mt-2 pl-2 text-sm text-red-600"><span class="font-medium">Erro!</span> Campo Inválido.</span>-->
</div>
