<app-navbar></app-navbar>

<!--Cards-->
<div class="justify-center mx-auto">
<div class="flex flex-wrap pb-12 m-auto">
  <div class="grid-cols-3 w-screen  mx-auto">
  </div>

<div class="animate__animated animate__fadeIn m-10 rounded-t-md max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl duration-75 hover:scale-105">
  <a
  routerLink="['/relatorios-diarios']"
  >
    <h1 class="titulo mb-0 rounded-t-md text-2xl font-bold text-white p-3 bg-[#955e1b]">Relatórios Diários</h1>
</a>
  <a routerLink="/relatorios-diarios">
      <img src="../../assets/Imagens/bg_relatorios.jpg" alt="tel-diarios" />
  </a>

  <div class="p-5">
    <a routerLink="/relatorios-diarios" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-[#955e1b] rounded-lg  focus:outline-none duration-75">
          VER TODOS
          <svg aria-hidden="true" class="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        </a>
  </div>

</div>

<div class="animate__animated animate__fadeIn m-10 rounded-t-md max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl duration-75 hover:scale-105">
  <a  routerLink="/vendedores">
    <h1 class="titulo mb-0 rounded-t-md text-2xl font-bold text-white p-3 bg-[#955e1b]">Vendedores</h1>
</a>
  <a routerLink="/vendedores">
      <img class="backdrop-50" src="../../assets/Imagens/bg_clientes.jpg" alt="clientes-pen" />
  </a>
  <div class="p-5">

      <a routerLink="/vendedores" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-[#955e1b] rounded-lg  focus:outline-none duration-75">
          VER TODOS
          <svg aria-hidden="true" class="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      </a>
  </div>
</div>

<div class="animate__animated animate__fadeIn m-10 rounded-t-md max-w-sm bg-white border border-gray-200 rounded-lg shadow-xl duration-75 hover:scale-105">
  <a routerLink="/listagem-entradas-saidas" >
    <h1 class="titulo mb-0 rounded-t-md text-2xl font-bold text-white p-3 bg-[#955e1b]">Listagens Entradas e Saídas</h1>
</a>
  <a routerLink="/listagem-entradas-saidas">
      <img src="../../assets/Imagens/bg-entradas-saidas.jpg" alt="provas" />
  </a>
  <div class="p-5">

      <a routerLink="/listagem-entradas-saidas" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-[#955e1b] rounded-lg  focus:outline-none duration-75">
          VER TODOS
          <svg aria-hidden="true" class="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      </a>
  </div>
</div>
</div>
</div>
