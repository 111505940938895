<app-navbar></app-navbar>

<!--Top Bar Title-->
<nav class="container max-w-screen bg-white border-gray-200 px-4 py-3 mx-auto">
  <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
      <button (click)="back()" class="inline-flex items-center">
        <svg class="animate__animated animate__fadeInRight w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
        <h1 *ngIf="cm" class="animate__animated animate__fadeIn titulo-top items-center pl-3"> {{cm.cmdesc}} Nº<!-- Nº do Cliente Pendente --> {{cm.cm}}</h1>
      </button>
  </div>
</nav>

<!-- -->
<div class="max-w-screen-xl px-4 py-3 mx-auto" *ngIf="cm">
  <div class="grid grid-cols-3 gap-4">
    <div>
      <label class="block text-sm font-medium text-[#955e1b] font-semibold">Nome</label>
     <!-- (Nome do Cliente) -->  {{cm.nome}}
    </div>
    <div>
      <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Número</label>
      <!-- (Nome do Produto) -->  {{cm.cm}}
    </div>
    <div>
      <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">email</label>
     <!-- (Nome do Produto) -->  {{cm.email}}
    </div>
  </div>

   @defer (when cm.viatura) {
    <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Viatura</label>
    <p><!-- (Nome do Produto) -->  {{cm.viatura.matricula}}</p>
    <div class="grid grid-cols-3 gap-4">
      <div>

       <!-- (Nome do Produto) -->  {{cm.viatura.tipo}}
      </div>
      <div>
        <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">marca</label>
       <!-- (Nome do Produto) -->  {{cm.viatura.marca}}
      </div>
      <div>
        <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">ano</label>
       <!-- (Nome do Produto) -->  {{cm.viatura.ano}}
      </div>
      <div>
        <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">KLM´s</label>
       <!-- (Nome do Produto) -->  {{cm.viatura.u_klmact}}
      </div>

    </div>
   }



  <!--
  <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Kms (Entrada)</label>
  <p>--><!-- (Resultado) --> <!--10</p>-->
<!--

  /*
  @defer (when cm) {
    <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Dados CM </label>
    <pre> {{cm | json }}</pre>

   }

   */ -->
</div>
