import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthUtils } from './auth.utils';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private _authService: AuthService, private _router: Router)
    {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        console.log( ['this._authService.accessToken', this._authService.accessToken]);
        console.log( ['AuthUtils.isTokenExpired(this._authService.accessToken)', AuthUtils.isTokenExpired(this._authService.accessToken)]);
       // if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) )
        if ( this._authService.accessToken  )
        {
          console.log("Cria o header PARA API");
          if (this._authService.accessToken !== '')
          newReq = req.clone({
        // Alterar para ser compativel com o nosso API login
        headers: req.headers.set('x-auth-token',  this._authService.accessToken)
      });
    }else {
          console.log("[Else ]Cria o header PARA API",req);

        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if ( error instanceof HttpErrorResponse && error.status === 401 )
                {
                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                   // location.reload();
                   this._router.navigate(['/sign-in']);
                }

                return throwError(error);
            })
        );
    }
}
