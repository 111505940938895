<app-navbar></app-navbar>

<!--Top Bar-->
<nav class="max-w-screen bg-white border-gray-200 px-4 py-3 mx-auto top-bar">
  <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen pt-4 pb-4">
      <a routerLink ="/mcrios" class="inline-flex items-center">
        <svg class="w-6 animate__animated animate__fadeInRight" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
        <h1 class="animate__animated animate__fadeIn titulo-top items-center pl-3">Vendedores</h1>
      </a>
  </div>
</nav>
<nav class="bg-white second-top-bar">
  <div class="container max-w-screen px-4 py-3 mx-auto hidden">
      <div>
          <ul class="flex flex-row font-medium mt-0 mr-6 space-x-8 text-sm">
            <form class="animate__animated animate__fadeInUp w-full">
              <div class="pb-3">
                  <label for="search-dropdown" class="mb-2 text-sm font-medium text-gray-900 sr-only"></label>
                  <div class="relative w-full">
                      <input type="search" id="search-dropdown" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-300 focus:ring-[#955e1b] focus:border-[#955e1b]" placeholder="Nome, nº de cliente, etc... " required>
                      <button type="submit" class="h-full absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-[#955e1b] rounded-r-lg border-gray-900 hover:bg-[#b0c950] duration-200 :outline-none">
                          <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                          <span class="sr-only">Procurar</span>
                      </button>
                  </div>
              </div>
            </form>
          </ul>
      </div>
  </div>
</nav>



<!--Listagem Vendedores (Só para aparecer internamente/administradores)-->
<div class="container justify-center mx-auto pt-12 p-4 pb-56 content">
  <ul class="max-w-screen divide-y divide-gray-200 mb-12">


    <li *ngFor=" let cm of vendedores" class="pb-3 sm:pb-4">
      <a [routerLink]="['/detalhes-vendedor', { no: cm.cm }]">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              {{cm.cmdesc}} Nº <!--{Var}-->  {{cm.cm}}
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>



  </ul>
  </div>
