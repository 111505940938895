<app-navbar></app-navbar>

<!--Top Bar Title-->
<nav class="container max-w-screen bg-white border-gray-200 px-4 py-3 mx-auto">
  <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
      <button  (click)="back()"  class="inline-flex items-center">
        <svg class="animate__animated animate__fadeInRight w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
        <h1 class="animate__animated animate__fadeIn titulo-top items-center pl-3">Listagem Entradas e Saídas Nº<!-- Nº do Cliente Pendente -->45215</h1>
      </button>
  </div>
</nav>


<!-- Detalhes Relatório Diário -->
<div class="max-w-screen-xl px-4 py-3 mx-auto pb-56">

  <label class="block text-sm font-medium text-[#955e1b] font-semibold">Data do Documento</label>
  <p><!-- (Data do Doc) --> 26/09/2022</p>

  <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Nome de Comercial</label>
  <p><!-- (Nome Comercial) --> Carlos Rios</p>

  <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Acompanhamento</label>
  <p><!-- (Acompanhamento) --> Engº Costa</p>


  <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Hora de Entrada</label>
  <p><!-- (Hora Entrada) --> 15:00</p>

  <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Hora de Saída</label>
  <p><!-- (Hora Saída) --> 19:30</p>



  <div class="shadow-xl pt-4 pl-4 pb-24">
  <p class="pt-4 pl-4 text-lg text-[#955e1b] font-semibold">Cliente</p>
  <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Hora de Entrada</label>
  <p><!-- (Hora Entrada) --> 15:00</p>

  <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Hora de Saída</label>
  <p><!-- (Hora Saída) --> 19:30</p>

  </div>
</div>

