import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'
import { McriosService } from '../core/app/mcrios.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-detalhes-rel-diario',
  templateUrl: './detalhes-rel-diario.component.html',
  styleUrls: ['./detalhes-rel-diario.component.css']
})
export class DetalhesRelDiarioComponent {

  private u_rdiariosstamp;
  urdiario:any;
  ldiario: any[]
  mapOptions: google.maps.MapOptions = {
   // center: { lat: 38.9987208, lng: -77.2538699 },
    zoom: 14,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,

  };
  constructor(private route: ActivatedRoute,private location: Location, private _McriosService: McriosService,
    ) { }
  ngOnInit() {
    this.route.params.subscribe(params => {
   //   const id = params['id'];
      this.u_rdiariosstamp = params['id'];
      // Faça algo com o valor do parâmetro 'id'
      this._McriosService.getDiario(this.u_rdiariosstamp).subscribe((data: any[]) => {
        this.urdiario = data;
        console.log('data', data);
        let rp =0 ;
        data['linhas'].forEach((element: any , i:number ):void => {
          element['vis'] = false;
          setTimeout(() => {

            this.toggleVisibility(i );
          }, 300 );
        }
        );
      });
    });
  }

  back(): void {
    this.location.back()
  }

  toggleVisibility(i) {
    console.log('toggleVisibility -> i', i);
    const element = document.getElementById(`vis_${i}`);
    if (element) {
      element.classList.toggle('hidden');
    }
  }


  async addc(ri,linha) {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Comentário",
      inputPlaceholder: "adicione um comentario na intervenção de " + linha.nome,
      inputAttributes: {
        "aria-label": "comentário"
      },
      showCancelButton: true
    });
    if (text) {
     //  Swal.fire(text);

     this._McriosService.saveComentario( {linha: linha, comentario : text}).subscribe((data: any[]) => {
      console.log('data', data);
    //  linha.comentario = text;
      this.urdiario['linhas'][ri]['comentario'].push(data);
     });

    }
  }

}
