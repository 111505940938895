import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Validador para o código postal de Portugal
export function codigoPostalValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = /^[0-9]{4}-[0-9]{3}$/.test(control.value);
    return valid ? null : { invalidCodigoPostal: true };
  };
}

// Validador para o NIF de Portugal
export function nifValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = /^[0-9]{9}$/.test(control.value);
    if (control.value === '') {
      return null;
    }
    return valid ? null : { invalidNif: true };
  };
}
