import { Component } from '@angular/core';
import {FormControl, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-novo-vendedor',
  templateUrl: './novo-vendedor.component.html',
  styleUrls: ['./novo-vendedor.component.css']
})
export class NovoVendedorComponent {
  user = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required]);
  confirm = new FormControl('', [Validators.required]);
  nome = new FormControl('', [Validators.required]);
  numero = new FormControl('', [Validators.required]);
  matricula = new FormControl('', [Validators.required]);
  kms = new FormControl('', [Validators.required]);

}
