import { Component } from '@angular/core';
import { Location } from '@angular/common'

@Component({
  selector: 'app-detalhes-entradas-saidas',
  templateUrl: './detalhes-entradas-saidas.component.html',
  styleUrls: ['./detalhes-entradas-saidas.component.css']
})
export class DetalhesEntradasSaidasComponent {
  constructor(private location: Location) {}

  back(): void {
    this.location.back()
  }
}
