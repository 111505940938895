<app-navbar></app-navbar>

<nav class="max-w-screen bg-white border-gray-200 px-4 py-3 mx-auto top-bar">
  <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
    <a routerLink="/mcrios" class="inline-flex items-center">
      <svg class="animate__animated animate__fadeInRight w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
      </svg>
      <h1 *ngIf="rdiario" class="animate__animated animate__fadeIn titulo-top items-center pl-3"> Relatório {{rdiario.no}} </h1>
    </a>
  </div>
</nav>

<form [formGroup]="userForm" *ngIf="rdiario" class="max-w-screen-xl px-4 py-3 mx-auto second-content" (ngSubmit)="submit(userForm.value)">
  <div>
    <ul class="max-w-screen divide-y divide-gray-200">
      <li class="pb-3 sm:pb-4">
        <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
            <p class="text-lg font-medium text-gray-900 truncate">{{ URDiarios.dia.substring(0, 10) }}</p>
          </div>
        </div>
      </li>
    </ul>

    <div class="flex items-stretch">
      <div class="mb-6">
        <label for="iklm"   class="block mb-2 text-sm font-medium text-gray-900"> {{rdiario.matricula}} Kms (Entrada)</label>
        <input formControlName="iklm"  [(ngModel)]="URDiarios.iklm" name="iklm" type="number" id="iklm"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5">
      </div>
      <div class="mb-6">
        <label for="fim" class="block mb-2 text-sm font-medium text-gray-900"> (fim)</label>
        <input formControlName="fklm"  [(ngModel)]="URDiarios.fklm" name="fklm" type="number" id="fklm"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5">
      </div>
    </div>

    <div class="mb-6">
      <label for="acompanhamento" class="block mb-2 text-sm font-medium text-gray-900">Acompanhamento ->{{rdiario.acompanhamento }}</label>
      <input formControlName="acompanhamento"  [(ngModel)]="URDiarios.acompanhamento"   name="acompanhamento" type="text" id="acompanhamento"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5">
    </div>

    <div class="mb-6">
      <label for="relatorio" class="block mb-2 text-sm font-medium text-gray-900">Relatório</label>
      <textarea formControlName="relatorio"  [(ngModel)]="URDiarios.relatorio"  name="relatorio" id="rel" rows="4"
        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-[#955e1b] focus:border-[#955e1b]"
        placeholder="Escreva o seu relatório aqui..."></textarea>
    </div>

    <!-- Adicionar Cliente (Ligação ao PHC) -->
    <div formGroupName="visitas">
      <div >
        <div *ngFor="let olinha of URDiarios.linhas; let i = index"  id="div{{i}}" class="mb-6 shadow-xl rounded-lg">
          <div *ngIf="olinha.u_ldiariosstamp.length > 0 && olinha.marcada == 0 ">
            <div class="p-5">
              <p id="t_{{i}}" class="font-semibold text-[#955e1b] text-xl cursor-pointer" (click)="toggleVisibility(i)">[{{olinha.nome}}] </p>
              <div class="flex items-stretch" id="vis_{{i}}" [ngClass]="{'hidden': !olinha.visible}">
                <div class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5">
                  <div>
                    <table class="table-auto">
                      <thead>
                        <tr>
                          <th class="px-4 py-2">Início</th>
                          <th class="px-4 py-2">Fim</th>
                          <th class="px-4 py-2">Duração</th>
                          <th class="px-4 py-2">Tipo </th>
                          <th class="px-4 py-2">Objectivo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="border">
                          <td class="px-4 py-2">{{ olinha.inicio }}</td>
                          <td class="px-4 py-2">{{ olinha.fim }}</td>
                          <td class="px-4 py-2">{{ olinha.duracao }}</td>
                          <td class="px-4 py-2">
@if(olinha.tipo == 1) {
                            Presencial ({{ formatLocalidade(olinha.localidade)}})
}
@if(olinha.tipo == 2) {
Remoto
        } @if(olinha.tipo == 3) {
          prospecção de mercado
        }

                          </td>
                          <td class="px-4 py-2">
                            <span *ngIf="olinha.isvenda ===1 " class="pl-4">✅Venda <span *ngIf="olinha.encomenda ===1 "> ✅ tem encomenda</span></span>
                            <span *ngIf="olinha.iscobranca ===1 " class="pl-4">💶Cobrança </span>
                            <span *ngIf="olinha.isreclamacao ===1 " class="pl-4">🚨Reclamação </span>
                            <span *ngIf="olinha.isposvenda ===1 " class="pl-4">🚜Pos Venda </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="w-16">
                      <button matTooltip="Remove visita {{ olinha.nome }}" (click)="devLinhaOnDB(i)" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                          <g><rect fill="none" height="24" width="24" /></g>
                          <g><path d="M14,8c0-2.21-1.79-4-4-4S6,5.79,6,8s1.79,4,4,4S14,10.21,14,8z M17,10v2h6v-2H17z M2,18v2h16v-2c0-2.66-5.33-4-8-4 S2,15.34,2,18z" /></g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-5" *ngIf="olinha.u_ldiariosstamp.length === 0 ">
            @if (olinha.nome) {
              <p class="font-semibold text-[#955e1b] text-xl" *ngIf="olinha.nome">
                <button (click)="delDiv(div)" matTooltip="Remove Cliente" type="button" data-tooltip-target="tooltip-cliente"
                  class="focus:outline-none hover:scale-105 duration-75 font-medium rounded-lg text-sm w-full h-full sm:w-auto px-5 py-2.5 text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="red">
                    <g><rect fill="none" height="24" width="24" /></g>
                    <g><path d="M14,8c0-2.21-1.79-4-4-4S6,5.79,6,8s1.79,4,4,4S14,10.21,14,8z M17,10v2h6v-2H17z M2,18v2h16v-2c0-2.66-5.33-4-8-4 S2,15.34,2,18z" /></g>
                  </svg>
                </button>
                [{{ olinha.nome }}]


              </p>
            }

            @else {
                <!-- Botao criar contacto-->
                <button mat-raised-button (click)="addCliente(i)" type="button">Criar Contacto</button>
            }

            <div class="flex items-stretch" *ngIf="olinha.nome">
              <div class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5">
                <div class="flex">
                  <mat-form-field class="time h-12 leading-10 text-xl" style="border: 0px;" appearance="outline" floatLabel="auto">
                    <mat-label for="inicio" class="block mb-2 text-sm font-medium text-gray-900 h-12">Hora de Entrada</mat-label>
                    <input id="inicio{{ i }}" matInput type="time" matTimepicker [(ngModel)]="olinha.inicio" name="inicio" formControlName="inicio" min="00:00" max="24:00" placeholder="Hora de Entrada" style="margin-top: 5px;">
                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon></mat-form-field>

                </div>
              </div>

              <div class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5">
                <div class="flex">
                  <mat-form-field class="time h-12 leading-10 text-xl" appearance="outline">
                    <mat-label for="Fim" class="block mb-2 text-sm font-medium text-gray-900 h-12">Hora de Saída</mat-label>
                    <input id="fim{{ i }}" matInput type="time" matTimepicker  [(ngModel)]="olinha.fim"  name="fim" formControlName="fim" min="00:00" max="24:00" style="margin-top: 5px;">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="flex items-stretch">
              <div class="flex-auto">
                <label for="nome" class="pt-2 block mb-2 text-sm font-medium text-gray-900">Nome: </label>
                <ng-select formControlName="nome"  id="nome{{ i }}"   [(ngModel)]="olinha.nome"   [items]="options" bindLabel="nome" [loading]="peopleLoading"
                  (change)="revento(i,'change',$event)"
                  (add)="revento(i,'add',$event)"
                  (search)="revento(i,'search',$event)"
                  (open)="revento(i,'open',$event)"
                  bindValue="nome" [searchWhileComposing]="true" [required]="true" [minTermLength]=3>
                </ng-select>
                <div>
                  no: {{olinha.no}}
                  tlm: {{olinha.contacto}}
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2 gap-4" *ngIf="olinha.nome">
              <section class="tipo">
                <label for="checkbox" class="pt-2 block mb-2 text-sm font-medium text-gray-900">Tipo de Contacto</label>
                <div class="flex">
                  <mat-radio-group  id="tipo{{ i }}"   class="example-radio-group" [(ngModel)]="olinha.tipo"    formControlName="tipo" aria-label="selecione o Tipo de Contacto" required>
                    <mat-radio-button value="1">Presencial ({{ formatLocalidade(olinha.localidade)}}) </mat-radio-button>
                    <mat-radio-button value="2">Remoto</mat-radio-button>
                    <mat-radio-button value="3">prospecção de mercado</mat-radio-button>
                  </mat-radio-group>
                </div>
              </section>

              <section class="Objectivo" *ngIf="olinha.tipo > 0 && olinha.tipo < 3  ">
                <label class="robj pt-2 block mb-2 text-sm font-medium text-gray-900">Objectivo: </label>
                <mat-checkbox  [(ngModel)]="olinha.isvenda"   id="isvenda{{ i }}"    formControlName="isvenda">Venda</mat-checkbox>
                <mat-checkbox  [(ngModel)]="olinha.iscobranca"  id="iscobranca{{ i }}"    formControlName="iscobranca">Cobrança</mat-checkbox>
                <mat-checkbox  [(ngModel)]="olinha.isposvenda" id="isposvenda{{ i }}"     formControlName="isposvenda">Pos Venda</mat-checkbox>
                <mat-checkbox   [(ngModel)]="olinha.isreclamacao"   id="isreclamacao{{ i }}"   formControlName="isreclamacao">Reclamação</mat-checkbox>
              </section>

            </div>

            <div *ngIf="olinha.isreclamacao">
              <div class="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full p-2.5">
                <button type="button" mat-raised-button (click)="fileInput.click()">Anexar imagens!</button>
                <input hidden multiple (change)="onFileSelected($event)" #fileInput type="file">
                <span class="file-name"></span>
                <section [ngSwitch]="status">
                  <p *ngSwitchCase="'uploading'">⏳ Uploading...</p>
                  <p *ngSwitchCase="'success'">✅ OK!</p>
                  <p *ngSwitchCase="'fail'">❌ Erro no upload!</p>
                  <p *ngSwitchDefault></p>
                </section>
                <div class="flex flex-wrap">
                  <div *ngFor="let file of $rfiles" class="w-32 h-32 object-cover rounded-lg mr-2 mb-2">
                    <img [src]="'/CamposPrivados/' + file.path" alt="{{file.name}}">
                  </div>
                </div>
              </div>
            </div>

            <section *ngIf="olinha.nome" class="produtos flex justify-center items-center flex-col w-full max-w-screen-md">
              <label for="produtos" class="w-full max-w-screen-md pt-2 block mb-2 text-sm font-medium text-gray-900">Produtos</label>
              <select2 #obprodutos [data]="data" id="produtos{{ i }}"   [(ngModel)]="olinha.produtos"    formControlName="produtos" class="w-full max-w-screen-md" overlay displaySearchStatus="always" multiple hideSelectedItems
                listPosition="above" infiniteScroll (update)="SelectUpdate($event)" (ngModelChange)="onSelectionChange()">
              </select2>
            </section>

            <div *ngIf="olinha.isvenda">
              <label for="encomenda" class="pt-2 block mb-2 text-sm font-medium text-gray-900">Tem encomenda?</label>
              <div class="flex items-center mb-4">
                <input id="encomenda"  id="encomenda{{ i }}"  [(ngModel)]="olinha.encomenda"  formControlName="encomenda" type="checkbox" class="w-4 h-4 text-[#955e1b] bg-gray-100 border-gray-300 rounded focus:ring-[#955e1b] focus:ring-2">
                <label for="encomenda" class="ml-2 text-sm font-medium text-gray-900">Sim</label>
              </div>
            </div>

            <div class="flex items-center mb-4" *ngIf="olinha.nome">
              <label for="ausente" class="pt-2 block mb-2 text-sm font-medium text-gray-900">Ausente?</label>
              <input id="ausente" id="ausente{{ i }}"  [(ngModel)]="olinha.ausente"  formControlName="ausente" type="checkbox" class="w-4 h-4 text-[#955e1b] bg-gray-100 border-gray-300 rounded focus:ring-[#955e1b] focus:ring-2">
            </div>

            <div *ngIf="olinha.tipo > 0">
              <label for="com" class="pt-2 block mb-2 text-sm font-medium text-gray-900">Comentários</label>
              <textarea formControlName="comentarios" id="comentarios{{ i }}"   [(ngModel)]="olinha.comentarios"    name="comentarios" id="comentarios" rows="4"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-[#955e1b] focus:border-[#955e1b]"
                placeholder="Escreva aqui os seus comentários..."></textarea>
            </div>

            <div *ngIf="olinha.tipo > 0">
              <mat-form-field *ngIf="false">
                <mat-label>Enviar Cópia</mat-label>
                <mat-select formControlName="emailcc" id="emailcc{{ i }}"  multiple>
                  <mat-option *ngFor="let mail of mailList" [value]="mail">{{mail}}</mat-option>
                </mat-select>
              </mat-form-field>

              <p>
                <button mat-raised-button (click)="sendBcc(i)" type="button">Enviar Email</button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
                {{bcc}}
              </p>
            </div>

            <input type="hidden" id="latitude{{ i }}"   [(ngModel)]="olinha.latitude"   formControlName="latitude" [value]="lat">
            <input type="hidden" id="longitude{{ i }}"    [(ngModel)]="olinha.longitude"  formControlName="longitude" [value]="lng">

            <div class="flex flex-col space-y-4">
              <div class="flex justify-between items-center">
                <h2 class="text-lg font-medium text-gray-900" (click)="showMap = !showMap"></h2>
                <button type="button" class="text-sm font-medium text-gray-500" (click)="showMap = !showMap">
                  <span *ngIf="!showMap">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" viewBox="0 0 20 20"
                      fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12zm-1-4a1 1 0 112 0v1a1 1 0 11-2 0v-1zm0-7a1 1 0 110-2 1 1 0 010 2z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                  <span *ngIf="showMap">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" viewBox="0 0 20 20"
                      fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12zm-1-4a1 1 0 112 0v1a1 1 0 11-2 0v-1zm0-7a1 1 0 110-2 1 1 0 010 2z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                  {{ showMap ? 'Fechar mapa' : 'Abrir mapa' }}
                </button>
                <h2 class="text-lg font-medium text-gray-900">Coordenadas {{showMap }}</h2>
              </div>
              <div *ngIf="showMap && lat" class="w-full max-w-lg mx-auto">
                <h2 class="text-lg font-medium text-gray-900">Mapa</h2>
                <google-map height="400px" [options]="mapOptions">
                  <map-marker [position]="mapOptions.center"></map-marker>
                </google-map>
              </div>
              <p id="nCL">&nbsp;</p>
            </div>



          </div>

        </div>
      </div>
    </div>
  </div>

</form>
<div>
  <span>

  </span>
</div>

