<app-navbar></app-navbar>

<!--Top Bar-->
<nav class="max-w-screen bg-white border-gray-200 px-4 py-3 mx-auto top-bar-ent-sai">
  <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen pt-4 pb-4">
      <a routerLink="/mcrios" class="inline-flex items-center">
        <svg class="w-6 animate__animated animate__fadeInRight" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
        <h1 class="animate__animated animate__fadeIn titulo-top items-center pl-3">Entradas e Saídas</h1>
      </a>
  </div>
</nav>
<nav *ngIf="onLoad"  class="container max-w-screen bg-white border-gray-200 px-4 py-3 mx-auto top-bar">
  <div class="container max-w-screen px-4 py-3 mx-auto">
    <div class="mb-5 h-4 overflow-hidden rounded-full bg-gray-200">
      <div class="h-4 animate-pulse rounded-full bg-gradient-to-r from-green-500 to-blue-500" style="width: 75%"></div>
    </div>
</div>
</nav>
<!-- Filtros por vendedor e por data -->
<nav class="bg-white second-top-bar">
  <div class="container max-w-screen px-4 py-3 mx-auto">
      <div>
          <ul class="flex flex-row font-medium mt-0 mr-6 space-x-8 text-sm">
            <form class="animate__animated animate__fadeInUp w-full">
              <div class="grid grid-cols-2 gap-1">
                <!-- Data -->
                <div class="relative w-full">
                  <div class="mat-form-field">
                    <label class="block text-gray-500 text-sm">Entre datas</label>
                    <div class="relative flex items-center space-x-2">
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker" class="relative">
                        <input matStartDate formControlName="start" placeholder="inicio" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full pl-10 p-2.5">
                        <input matEndDate formControlName="end" placeholder="fim" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] block w-full pl-10 p-2.5">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matIconSuffix [for]="picker" class="m-0"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    </div>
                    <div class="text-gray-500 text-sm"></div>
                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')" class="text-red-500 text-sm">data invalida</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')" class="text-red-500 text-sm">data invalida</mat-error>
                  </div>
                </div>

                <!-- Filtro por Cliente (Ligação PHC) -->
                <div class="relative w-full">
                  <mat-form-field>
                    <mat-label>Vendedor</mat-label>
                    <mat-select [formControl]="vendedores" multiple class="block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#955e1b] focus:border-[#955e1b] p-2.5">
                      <mat-option *ngFor="let vendedor of vendedoresList" [value]="vendedor.cm">{{vendedor.cmdesc}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <!-- Botão de Pesquisa com ícone de lupa -->
              <button type="button" (click)="submitPesquisa()" class="bg-[#955e1b] text-white rounded-lg p-2.5 hover:bg-[#704a18]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-4.351-4.35M15 9a6 6 0 11-12 0 6 6 0 0112 0z"/>
                </svg>
              </button>
            </form>


          </ul>
      </div>
  </div>
</nav>
<div *ngIf="diarios.length > 0" class="container justify-center mx-auto pt-1 p-4 pb-56 second-content">
  [{{center | json}}]] -->
  <div class="map">
  <google-map height="750" width="750" [options]="mapOptions">

    <map-marker #marker="mapMarker" [optimized]="false"
     [options]="markerOptions" [icon]="visita.icon"
     (mapClick)="openInfoWindow(marker,visita)"
       *ngFor="let visita of visitas; let i=index"

        [position]="visita.position"
        [title]="visita.title" [label]="">

        <map-info-window>

          <ul>
            <li>dia: {{i}}-- {{rvisita.dia}} </li>
            <li>inicio:  {{rvisita.inicio}} </li>
            <li>Fim:  {{rvisita.fim}} </li>
            <li>Comentarios  {{rvisita.comentarios}} </li>
          </ul>
          <span [routerLink]="['/detalhes-relatorio-diario/',rvisita.u_rdiariosstamp]" routerLinkActive="router-link-active" >
            <p> <b>{{rvisita.title}}</b> </p>
          </span>
         </map-info-window>
      </map-marker>
  </google-map>
</div>
  <!-- FILEPATH: /home/reage/MCRIOSUi/src/app/list-entradas-saidas/list-entradas-saidas.component.html -->

<!-- Listagem de Visitas -->
<div class="container justify-center mx-auto pt-1 p-4 pb-56 second-content">

  <ul class="max-w-screen divide-y divide-gray-200 mb-12">
    <li class="pb-3 sm:pb-4" *ngFor="let visita of visitas">
      <div [routerLink]="['/detalhes-relatorio-diario/',visita.u_rdiariosstamp]" routerLinkActive="router-link-active"  class="flex items-center space-x-4">
        <div class="flex-1 min-w-0">
          <p class="text-lg font-medium text-gray-900 truncate">{{ visita.title }}</p>
          <p class="text-sm font-medium text-gray-500">{{ visita.localidade}} bbb {{ visita.inicio }} - {{ visita.fim }}</p>
          <p class="text-sm font-medium text-gray-500">{{ visita.comentarios }}</p>
          <p> {{ visita | json }}</p>
        </div>
      </div>
    </li>
  </ul>
</div>


</div>
<div id="map"></div>




<!--Listagem Vendedores (Só para aparecer internamente/administradores (Uma vez por semana ou mês))-->
<div *ngIf="false" class="container justify-center mx-auto pt-1 p-4 pb-56 second-content">
  <ul class="max-w-screen divide-y divide-gray-200 mb-12">
    <li class="pb-3 sm:pb-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Carlos Rios Nº <!--{Var}--> 23
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>


    <li class="py-3 sm:py-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Pedro Dias Nº <!--{Var}--> 13
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>


    <li class="py-3 sm:py-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Engº Costa Nº <!--{Var}--> 08
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>

    <li class="py-3 sm:py-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Luís Macedo Nº <!--{Var}--> 20
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>


    <li class="pt-3 pb-0 sm:pt-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Margarida Dias Nº <!--{Var}--> 19
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>

    <li class="pt-3 pb-0 sm:pt-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Nelson Nº <!--{Var}--> 19
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>


    <li class="pt-3 pb-0 sm:pt-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Valentim Nº <!--{Var}--> 19
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>

    <li class="pt-3 pb-0 sm:pt-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Fernando Tavares Nº <!--{Var}--> 19
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>


  <li class="pt-3 pb-0 sm:pt-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              António José Nº <!--{Var}--> 19
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>

    <li class="pt-3 pb-0 sm:pt-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Dina Camarinho Nº <!--{Var}--> 19
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>

    <li class="pt-3 pb-0 sm:pt-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Sara Rios Nº <!--{Var}--> 19
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>

    <li class="pt-3 pb-0 sm:pt-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Marina Vinhas Nº <!--{Var}--> 19
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>

    <li class="pt-3 pb-0 sm:pt-4">
      <a href="/detalhes-entradas-saidas">
       <div class="flex items-center space-x-4">
          <div class="flex-1 min-w-0">
             <p class="text-lg font-medium text-gray-900 truncate">
              Márcio Silva Nº <!--{Var}--> 19
             </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
          </div>
       </div>
      </a>
    </li>

  </ul>
  </div>

