<h1 mat-dialog-title>Adicionar Contacto</h1>
<div mat-dialog-content>
  <form [formGroup]="clienteForm" class="cliente-form">
    <div class="form-row">
      <mat-form-field class="form-field">
        <mat-label>Nome</mat-label>
        <input matInput type="text" formControlName="nome">
        <mat-error *ngIf="clienteForm.controls['nome']?.touched && clienteForm.controls['nome']?.invalid">
          Nome é obrigatório
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email">
        <mat-error *ngIf="clienteForm.controls['email']?.touched && clienteForm.controls['email']?.invalid">
          Email é obrigatório
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field class="form-field">
        <mat-label>Telefone</mat-label>
        <input matInput type="tel" formControlName="telefone">
        <mat-error *ngIf="clienteForm.controls['telefone']?.touched && clienteForm.controls['telefone']?.invalid">

        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field">
        <mat-label>Morada</mat-label>
        <input matInput type="text" formControlName="morada">
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field class="form-field">
        <mat-label>Localidade</mat-label>
        <input matInput type="text" formControlName="localidade">
      </mat-form-field>

      <mat-form-field class="form-field">
        <mat-label>Código Postal</mat-label>
        <input matInput type="text" formControlName="codigoPostal">
        <mat-error *ngIf="clienteForm.controls['codigoPostal']?.touched && clienteForm.controls['codigoPostal']?.invalid">
          Código Postal é invalido
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field class="form-field">
        <mat-label>NIF</mat-label>
        <input matInput type="text" formControlName="nif">
        <mat-error *ngIf="clienteForm.controls['nif']?.touched && clienteForm.controls['nif']?.invalid">
          NIF com formato invalido
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field class="form-field">
        <mat-label>Notas</mat-label>
        <textarea matInput type="text" formControlName="notas"></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="dialog-actions">
 <!--  // apenas se o clienteForm for valido -->


  <button mat-button [disabled]="!clienteForm.valid"  (click)="onSave()">Guardar</button>
</div>
