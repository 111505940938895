<!-- FILEPATH: /home/reage/MCRIOSUi/src/app/detalhes-rel-diario/detalhes-rel-diario.component.html -->
<!-- BEGIN: abpxx6d04wxr -->
<app-navbar></app-navbar>

<!-- Top Bar Title -->
<nav class="container max-w-screen bg-white border-gray-200 px-4 py-3 mx-auto">
  <div *ngIf="urdiario" class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
    <button (click)="back()" class="inline-flex items-center">
      <svg class="animate__animated animate__fadeInRight w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path
          d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
      </svg>
      <h1 class="animate__animated animate__fadeIn titulo-top items-center pl-3">
        Relatório Diário Nº<!-- Nº do Cliente Pendente -->{{ urdiario.no }}
      </h1>
    </button>
  </div>
</nav>

<!-- Detalhes Relatório Diário -->
<div class="max-w-screen-xl px-4 py-3 mx-auto pb-56">
  <div class="grid grid-cols-2 gap-4">
    <div>
      <label class="block text-sm font-medium text-[#955e1b] font-semibold">Data do Documento</label>
      <p><!-- (Data do Doc) --> {{ urdiario.dia | slice:0:10 }}</p>

      <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Nome de Comercial</label>
      <p><!-- (Nome Comercial) --> {{ urdiario.cmdesc }}</p>

      <label *ngIf="urdiario.acompanhamento.length > 2 "
        class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Acompanhamento</label>
      <p *ngIf="urdiario.acompanhamento.length > 2 "><!-- (Acompanhamento) --> {{ urdiario.acompanhamento }}</p>

      <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Nº Clientes</label>
      <p><!-- (Qtd de Clientes) --> {{ urdiario.linhas.length }}</p>
    </div>

    <div>
      <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Matrícula da Viatura</label>
      <p><!-- (Matrícula) --> {{ urdiario.linhas.matricula }}</p>

      <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Horas</label>
      <p><!-- (Hora Entrada) --> {{ urdiario.inico }} até {{ urdiario.fim }}</p>



      <label *ngIf="urdiario.klm  > 0 "
        class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Kms</label>
      <p *ngIf="urdiario.klm  > 0 "><!-- (Kms) --> {{ urdiario.klm }}</p>


    </div>
  </div>
  <div>
    <div *ngIf="urdiario.relatorio.length > 1 ">
      <label class="pt-2 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Relatório</label>
      <p><!-- (Relatório) --> {{ urdiario.relatorio }}</p>
    </div>

    <div *ngFor="let linha of urdiario.linhas; let i = index" class="shadow-xl pt-4 pl-4 pb-24">
      <p  (click)="toggleVisibility(i)" class="pt-4 pl-4 text-lg text-[#955e1b] font-semibold">{{ linha.inicio | slice:0:5  }} - {{ linha.nome }}</p>

<div id="vis_{{i}}" >



      <div class="grid grid-cols-2 gap-2">
        <div *ngIf="+linha.latitude > 0">
          <google-map *ngIf="+linha.latitude > 0" height="400px" width="450px"
            [center]="{ lat: +linha.latitude, lng: +linha.longitude}" [options]="mapOptions">
            <map-marker [position]="{ lat: +linha.latitude, lng: +linha.longitude}"></map-marker>
          </google-map>
        </div>
        <div>
          <label *ngIf="linha.tipo * 1   > 0"
            class="pt-2 pl-4 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Tipo contacto</label>
          <p *ngIf="linha.tipo * 1  === 1" class="pl-4"> Fisico</p>
          <p *ngIf="linha.tipo * 1  === 2" class="pl-4"> Remoto</p>

          <label class="pt-2 pl-4 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Contacto</label>
          <p class="pl-4"><!-- (Contacto) --> {{ linha.contacto }}</p>

          <div>
            <label class="pt-2 pl-4 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Objetivo</label>
            <p *ngIf="linha.isvenda ===1 " class="pl-4">✅Venda <span *ngIf="linha.encomenda ===1 "> ✅ tem encomenda
              </span></p>
            <p *ngIf="linha.iscobranca ===1 " class="pl-4">💶Cobrança </p>
            <p *ngIf="linha.isreclamacao ===1 " class="pl-4">🚨Reclamação </p>
            <p *ngIf="linha.isposvenda ===1 " class="pl-4">🚜Pos Venda </p>


          </div>

          <div *ngIf="linha.comentarios.length > 3">
            <label class="pt-2 pl-4 block mb-2 text-sm font-medium text-[#955e1b] font-semibold">Comentários</label>
            <pre class="pl-4"><!-- (Comentários) --> {{ linha.comentarios }}</pre>
          </div>

        </div>
      </div>
      <div  class="container mx-auto space-y-2 lg:space-y-0 lg:gap-2 lg:grid lg:grid-cols-3">
        <div *ngFor="let anexo of linha.anexos;"   class="rounded hover:shadow-2xl">
          <a href="https://d2023.mcrios.pt/api/mcrios/GetAnexos/{{ anexo.anexosstamp }}" target="_blank">
            <img src="https://d2023.mcrios.pt/api/mcrios/GetAnexos/{{ anexo.anexosstamp }}" class=""
                alt="{{ anexo.descricao }}" >
          </a>
        </div>

    </div>


<!-- BEGIN: be15d9bcejpp -->
<div class="flex justify-center">
  <div class="w-full lg:w-1/2">
    <div class="flex items-center justify-between mb-4">

      <button (click)="addc(i,linha)" class="bg-[#955e1b] text-white px-4 py-2 rounded-lg">Adicionar Comentário</button>
    </div>
    <div class="space-y-4">
      <div *ngFor="let coment of linha.comentario; let ci=index" class="flex items-center space-x-4">
        <div class="w-12 h-12 bg-gray-200 rounded-full"></div>
        <div>
          <h3 class="text-lg font-semibold"> {{ coment.cmdesc }}</h3>
          <p class="text-gray-500">{{ coment.comentario }}</p>
          <p class="text-gray-500">{{ coment.ousrdata  }}</p>

        </div>
      </div>

      <!-- Add more comments here -->
    </div>
  </div>
</div>
<!-- END: be15d9bcejpp -->



    </div>

  </div>
  </div>

</div>
<!-- END: be15d9bcejpp -->
