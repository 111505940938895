import { DetalhesRelDiarioComponent } from './detalhes-rel-diario/detalhes-rel-diario.component';
import { ListRelDiariosComponent } from './list-rel-diarios/list-rel-diarios.component';
import { ListEntradasSaidasComponent } from './list-entradas-saidas/list-entradas-saidas.component';
import { NovoRelDiarioComponent } from './novo-rel-diario/novo-rel-diario.component';
import { NovoVendedorComponent } from './novo-vendedor/novo-vendedor.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AppMcriosComponent } from './app-mcrios/app-mcrios.component';
import { ListVendedoresComponent } from './list-vendedores/list-vendedores.component';
import { DetalhesVendedorComponent } from './detalhes-vendedor/detalhes-vendedor.component';
import { DetalhesEntradasSaidasComponent } from './detalhes-entradas-saidas/detalhes-entradas-saidas.component';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { ChangelogComponent } from './changelog/changelog.component';

const routes: Routes = [



  { path: '', pathMatch: 'full', redirectTo: 'mcrios' },
// sem guard
  { path: 'sign-in', component: LoginComponent, canActivate: [NoAuthGuard] ,canActivateChild: [NoAuthGuard]},

  { path: 'navbar', component: NavbarComponent , canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: 'mcrios',   component: AppMcriosComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: 'detalhes-relatorio-diario/:id', component: DetalhesRelDiarioComponent , canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: 'relatorios-diarios', component: ListRelDiariosComponent , canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: 'novo-relatorio-diario/:id', component: NovoRelDiarioComponent , canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: 'novo-vendedor', component: NovoVendedorComponent , canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: 'vendedores', component: ListVendedoresComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard] },
  { path: 'detalhes-vendedor', component: DetalhesVendedorComponent , canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: 'listagem-entradas-saidas', component:ListEntradasSaidasComponent , canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
  { path: 'detalhes-entradas-saidas', component: DetalhesEntradasSaidasComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard] },
  { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
